import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { MessageService, ConfirmationService } from 'primeng/api';
import { CompetitionsColumnsService } from '../../../services';
import { Competition } from '../../../interfaces';
import { Column } from '../../../interfaces/column.interface';

@Component({
  selector: 'app-competition-reservation-details',
  templateUrl: './competition-details.component.html',
  styleUrls: ['./competition-details.component.scss'],
  providers: [MessageService, ConfirmationService, DynamicDialogRef, DynamicDialogConfig],
})
export class CompetitionDetailsComponent implements OnInit {

  public competition: Competition = {};
  public columns: Column[] = [];

  constructor(
    private router: Router,
    private dynamicDialogConfig: DynamicDialogConfig,
    private competitionsColumnsService: CompetitionsColumnsService,

  ) { }

  ngOnInit(): void {
    if (this.dynamicDialogConfig.data && typeof this.dynamicDialogConfig.data.competition !== 'undefined') {
      this.competition = this.dynamicDialogConfig.data.competition;
    }
    this.columns = this.competitionsColumnsService.getColumns();
    for (let i = 0; i <= this.columns.length; i++) {
      if (this.columns[i].field === "actions") {
        this.columns.splice(i, 1);
      }
    }
  }

  list() {
    this.router.navigate(['/competition']);
  }

  add() {
    this.router.navigate(['/competition/add']);
  }

  edit() {
    this.router.navigate(['/competition/edit/' + this.competition.id]);
  }

}
@Component({
  selector: 'app-competition-reservation-details',
  templateUrl: './competition-details.component.html',
  styleUrls: ['./competition-details.component.scss'],
  providers: [MessageService, ConfirmationService],
})
export class DialogCompetitionDetailsComponent extends CompetitionDetailsComponent {
}

