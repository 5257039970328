import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { MessageService, ConfirmationService, PrimeNGConfig } from 'primeng/api';
import { Title } from '@angular/platform-browser';
import { NavigationService } from '../../../services/navigation/navigation.service';
import { ToolsService } from '../../../services/tools/tools.service';
import { Column } from '../../interfaces/column.interface';
import { MembersDataService, MembersColumnsService } from '../../services';
import { DialogMemberDetailsComponent } from './member-details/member-details.component';
import { Member, MembersResult } from '../../interfaces';

@Component({
  selector: 'app-members',
  templateUrl: './members.component.html',
  styleUrls: ['./members.component.scss'],
  providers: [MessageService, ConfirmationService, DialogService],
})
export class MembersComponent implements OnInit, OnDestroy {

  private memberAddDialog: DynamicDialogRef = new DynamicDialogRef();
  private memberEditDialog: DynamicDialogRef = new DynamicDialogRef();
  private memberDetailsDialog: DynamicDialogRef = new DynamicDialogRef();
  public members: Member[] = [];
  public columns: Column[] = [];
  public isLoading: boolean = false;
  public isInitialized: boolean = false;
  private _selectedColumns: Column[] = [];

  constructor(
    private translate: TranslateService,
    private navigation: NavigationService,
    private router: Router,
    private dialogService: DialogService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private primengConfig: PrimeNGConfig,
    private titleService: Title,
    private membersDataService: MembersDataService,
    private membersColumnsService: MembersColumnsService,
  ) {
  }

  ngOnInit(): void {
    this.isInitialized = true;
    this.titleService.setTitle('Members - Management');
    this.primengConfig.ripple = true;
    this.getMembers();
  }

  ngOnDestroy(): void {
    this.isInitialized = false;
  }

  getMembers(): void {
    this.isLoading = true;

    if (!this.isInitialized) {
      return;
    }
    this.membersDataService.getMembers().subscribe((response: MembersResult|any) => {
      this.isLoading = false;
      if (response !== null) {
        this.setColumns();
        this.members = response.success.data;
      }
    }, (error: any) => {;
      this.isLoading = false;
      this.messageService.add({severity: 'error', summary: this.translate.instant('UI.ERROR'), detail: this.translate.instant('UI.MEMBERSNOTLOADED')});
    });
  }

  setColumns() {
    if (this.columns.length > 0) {
      return;
    }
    this.columns = this.membersColumnsService.getColumns();
    for (let i = 0; i < this.columns.length; i++) {
      if (this.columns[i].show) {
        this._selectedColumns.push(this.columns[i]);
      }
    }
  }

  @Input()
  get selectedColumns(): any[] {
      return this._selectedColumns;
  }

  set selectedColumns(val: any[]) {
      // restore original order
      this._selectedColumns = this.columns.filter((col: any) => val.includes(col));
      this.updateColumns();
  }

  updateColumns() {
    for (let i = 0; i < this.columns.length; i++) {
      const show = this._selectedColumns.includes(this.columns[i]);
      this.columns[i].show = show;
      this.navigation.setParamValue('members.' + this.columns[i].field + '.showFiled', show);
    }
  }

  exportPdf() {
    ToolsService.exportPdf(this.members, this.columns, 'Members');
  }

  exportExcel() {
    ToolsService.exportExcel(this.members, this.columns, 'Members');
  }

  exportCsv() {
    ToolsService.exportCsv(this.members, this.columns, 'Members');
  }

  refresh() {
    this.getMembers();
  }

  memberDetails(member: Member): void {
    if (!this.navigation.getParamValue('members.detailsInDialog', true)) {
      this.router.navigate(['/members/details/' + member.id]);
      return;
    }
    this.memberDetailsDialog = this.dialogService.open(DialogMemberDetailsComponent, {
      width: '70%',
      contentStyle: { 'height': '800px', overflow: 'auto' },
      baseZIndex: 1000,
      data: {
        member: member,
      }
    });
  }

}
