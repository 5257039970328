
<div class="animated fadeIn">
  <div class="d-flex align-items-center">
      <h1>{{ 'GIFT.GIFTDETAILS' | translate }}</h1>
  </div>

  <p-card *ngIf="gift.id">
    <div class="p-grid">
      <div class="p-col-12" *ngFor="let col of columns">
        <div class="p-grid">
          <div class="p-col-4 text-primary">
            {{ col.title | translate }}
          </div>
          <div class="p-col-8">
            <app-table-cell-value [value]="gift[col.field]" [column]="col"></app-table-cell-value>
          </div>
        </div>
      </div>
    </div>

  </p-card>

</div>

