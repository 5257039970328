import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Category, CategoriesResult } from '../interfaces';

@Injectable({
  providedIn: 'root'
})
export class CategoriesDataService {

  private uri: string = `${environment.apiUrl}list-categories`;

  constructor(
    private http: HttpClient
  ) {
  }

  getCategories(): Observable<CategoriesResult|any> {
    return this.http.get(this.uri + '/all');
  }

  addCategory(data: Category): Observable<Category|any> {
    const cleanData = Object.assign({}, data);
    delete cleanData.id;
      return this.http.post(this.uri + '/add', cleanData);
  }

  updateCategory(data: Category): Observable<Category|any> {
    const cleanData = Object.assign({}, data);
    delete cleanData.id;
    return this.http.post(this.uri + "/update" +  `/${data.id}`, cleanData);
  }

  deleteCategory(data: Category): Observable<null|any> {
    return this.http.put(this.uri + "/update_active" + `/${data.id}`, "");
  }

}
