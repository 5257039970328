import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { formatDate } from '@angular/common';
import { environment } from '../../../../environments/environment';

@Component({
    selector: 'app-table-cell-value',
    templateUrl: './table-cell-value.component.html',
    styleUrls: ['./table-cell-value.component.scss'],
    providers: [],
})
export class TableCellValueComponent implements OnInit, OnDestroy {

    @Input()
    public column: any = {};

    @Input()
    public value: any = null;

    public userHtml: string = '';

    constructor(
        private translate: TranslateService,
    ) {

    }

    ngOnInit(): void {
        let html = '';
        if (this.value == null || typeof this.value == 'undefined') {
            this.userHtml = '';
            if (this.column.type === 'imgValue') {
                this.userHtml = '<img class="img-preview" src="/assets/img/no-image.svg" title="no image" alt="no image" />';
            }
        } else {
            switch (this.column.type) {
                case 'textValue':
                    this.userHtml = '<span class="text">' + this.value + '</span>';
                    break;
                case 'imgValue':
                    this.userHtml = '<img class="img-preview" src="' + environment.filesUrl + this.value + '" title="' + this.value + '"  alt="' + this.value + '" />';
                    break;
                case 'numericValue':
                    this.userHtml = '<span class="numeric">' + this.value + '</span>';
                    break;
                case 'booleanValue':
                    if (this.value) {
                        this.userHtml = '<span class="far fa-check-circle text-success boolean"></span>';
                    } else {
                        this.userHtml = '<span class="far fa-times-circle text-danger boolean"></span>';
                    }
                    break;
                case 'dateValue':
                    let locale = localStorage.getItem('locale');
                    if (locale === null) {
                        locale = 'fr';
                    }
                    this.userHtml = '<span class="date">' + formatDate(this.value + '', this.column.valueFormatter, 'fr') + '</span>';
                    break;
                case 'colorValue':
                    html = '<strong class="color" style="background-color:' + this.value + ';color:#fff">'
                    html += this.value.replace('#', '');
                    html += '</strong>';
                    this.userHtml = html;
                    break;
                case 'enumValue':
                    let css = 'btn btn-sm btn-';
                    let label = '';
                    for (var i in this.column.filterData) {
                        if (this.column.filterData[i].value === this.value) {
                            label = this.translate.instant(this.column.filterData[i].label);
                            css += this.column.filterData[i].css;
                        }
                    }
                    html = '<strong class="' + css + ' enum">'
                    html += label;
                    html += '</strong>';
                    this.userHtml = html;
                    break;
                case 'linkValue':
                    const displayFields = this.column.displayField.split(' ');
                    let displayText = '';
                    for (var i in displayFields) {
                        if (typeof this.value[displayFields[i]] !== 'undefined') {
                            displayText += this.value[displayFields[i]] + ' ';
                        }
                    }
                    if (displayText === '') {
                        displayText = '#' + this.value.id + '';
                    }
                    html = '';
                    if (this.checkUrlPermission(this.column.url)) {
                        html += '<a routerLink="' + this.column.url + '/' + this.value.id + '">';
                        html += displayText.trim();
                        html += '</a>';
                    } else {
                        html += displayText.trim();
                    }
                    this.userHtml = html;
                    break;
                case 'linksValue':
                    if (typeof this.value.length === 'undefined') {
                        this.userHtml = '';
                        break;
                    }
                    if (this.value.length == 0) {
                        this.userHtml = '';
                        break;
                    }
                    var links = [];
                    for (var i in this.value) {
                        let link = '';
                        if (this.checkUrlPermission(this.column.url)) {
                            link += '<a routerLink="' + this.column.url + '/' + this.value.id + '">';
                        }
                        const displayFields = this.column.displayField.split(' ');
                        let displayText = '';
                        for (var j in displayFields) {
                            if (typeof this.value[i][displayFields[j]] !== 'undefined') {
                                displayText += this.value[i][displayFields[j]] + ' ';
                            }
                        }
                        if (displayText === '') {
                            displayText = '#' + this.value[i].id + '';
                        }
                        link += displayText;
                        link = link.trim();
                        if (this.checkUrlPermission(this.column.url)) {
                            link += '</a>';
                        }
                        links.push(link);
                    }
                    this.userHtml = links.join(', ');
                    break;
                case 'arrayValue':
                    if (typeof this.value.length === 'undefined') {
                        this.userHtml = '';
                        break;
                    }
                    if (this.value.length == 0) {
                        this.userHtml = '';
                        break;
                    }
                    var links = [];
                    for (var i in this.value) {
                        let link = '<span>' + this.value[i] + '</span>';
                        links.push(link);
                    }
                    this.userHtml = links.join(', ');
                    break;
            }
        }
    }

    checkUrlPermission(url: string) {
        return true;
    }

    ngOnDestroy(): void {

    }

}
