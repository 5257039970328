import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NavigationService } from '../../services/navigation/navigation.service';
import { CacheService } from '../../services/cache/cache.service';
import { CommonService } from '../../services/common/common.service';
import { Column } from '../interfaces/column.interface';
import moment from 'moment';
import { User, UsersResult } from '../interfaces';

@Injectable({
  providedIn: 'root'
})
export class MembersColumnsService {

  public Gender:any[]= [
    {type: 'MAN'},
    {type: 'WOMEN'}
];
  public users: User[] = [];

  constructor(
    private translate: TranslateService,
    private navigation: NavigationService
  ) {

  }

  getColumns(): Column[] {
    return [
      //{ field: 'id', sortField: 'id', title: this.translate.instant('ID'), show: (this.navigation.getParamValue('members.id.showFiled', true) && true), displayInList: true, type: 'numericValue'},
      { field: 'phoneNumber', sortField: 'phoneNumber', title: this.translate.instant('CONTENT.PHONENUMBER'), show: (this.navigation.getParamValue('members.phoneNumber.showFiled', true) && true), displayInList: true, type: 'textValue'},
      //{ field: 'pushNotifications', sortField: 'pushNotifications', title: this.translate.instant('pushNotifications'), show: (this.navigation.getParamValue('members.pushNotifications.showFiled', true) && true), displayInList: true, type: 'textValue'},
      { field: 'height', sortField: 'height', title: this.translate.instant('CONTENT.HEIGHT'), show: (this.navigation.getParamValue('members.height.showFiled', true) && true), displayInList: true, type: 'numericValue'},
      //{ field: 'roles', sortField: 'roles', title: this.translate.instant('roles'), show: (this.navigation.getParamValue('members.roles.showFiled', true) && true), displayInList: true, type: 'textValue'},
      { field: 'numberOfPoints', sortField: 'numberOfPoints', title: this.translate.instant('CONTENT.NUMBEROFPOINTS'), show: (this.navigation.getParamValue('members.numberOfPoints.showFiled', false) && true), displayInList: true, type: 'numericValue' },
      { field: 'gender', sortField: 'gender', title: this.translate.instant('CONTENT.GENDER'), show: (this.navigation.getParamValue('members.gender.showFiled', true) && true), displayInList: true, type: 'textValue'},
      //{ field: 'status', sortField: 'status', title: this.translate.instant('status'), show: (this.navigation.getParamValue('members.status.showFiled', false) && true), displayInList: true, type: 'textValue' },
      { field: 'actions', title: this.translate.instant('CONTENT.ACTIONS'), type: 'static', show: true, displayInList: true, sortField: null},
    ];
  }

}
