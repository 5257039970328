import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { MessageService, ConfirmationService, PrimeNGConfig } from 'primeng/api';
import { Title } from '@angular/platform-browser';
import { NavigationService } from '../../../services/navigation/navigation.service';
import { ToolsService } from '../../../services/tools/tools.service';
import { Column } from '../../interfaces/column.interface';
import { ArticlesDataService, ArticlesColumnsService } from '../../services';
import { DialogArticleFormComponent } from './article-form/article-form.component';
import { DialogArticleDetailsComponent } from './article-details/article-details.component';
import { Article, ArticlesResult } from '../../interfaces';

@Component({
  selector: 'app-posts',
  templateUrl: './articles.component.html',
  styleUrls: ['./articles.component.scss'],
  providers: [MessageService, ConfirmationService, DialogService],
})
export class ArticlesComponent implements OnInit, OnDestroy {

  private articleAddDialog: DynamicDialogRef = new DynamicDialogRef();
  private articleEditDialog: DynamicDialogRef = new DynamicDialogRef();
  private articleDetailsDialog: DynamicDialogRef = new DynamicDialogRef();
  public articlesToDelete: Article = {};
  public articles: Article[] = [];
  public columns: Column[] = [];
  public isLoading: boolean = false;
  public isInitialized: boolean = false;
  private _selectedColumns: Column[] = [];

  constructor(
    private translate: TranslateService,
    private navigation: NavigationService,
    private router: Router,
    private dialogService: DialogService,
    private messageService: MessageService,
    private primengConfig: PrimeNGConfig,
    private titleService: Title,
    private articlesDataService: ArticlesDataService,
    private articlesColumnsService: ArticlesColumnsService,
  ) {
  }

  ngOnInit(): void {
    this.isInitialized = true;
    this.titleService.setTitle('Articles - Management');
    this.primengConfig.ripple = true;
    this.getArticles();
  }

  ngOnDestroy(): void {
    this.isInitialized = false;
  }

  getArticles(): void {
    this.isLoading = true;
    if (!this.isInitialized) {
      return;
    }
    this.articlesDataService.getArticles().subscribe((response: ArticlesResult|any) => {
      this.isLoading = false;
      if (response !== null) {
        this.setColumns();
        this.articles = response.success.data;
      }
    }, (error: any) => {;
      this.isLoading = false;
      this.messageService.add({severity: 'error', summary: this.translate.instant('UI.ERROR'), detail: this.translate.instant('UI.ARTICLESNOTLOADED')});
    });
  }

  setColumns() {
    if (this.columns.length > 0) {
      return;
    }
    this.columns = this.articlesColumnsService.getColumns();
    for (let i = 0; i < this.columns.length; i++) {
      if (this.columns[i].show) {
        this._selectedColumns.push(this.columns[i]);
      }
    }
  }

  @Input()
  get selectedColumns(): any[] {
      return this._selectedColumns;
  }

  set selectedColumns(val: any[]) {
      // restore original order
      this._selectedColumns = this.columns.filter((col: any) => val.includes(col));
      this.updateColumns();
  }

  updateColumns() {
    for (let i = 0; i < this.columns.length; i++) {
      const show = this._selectedColumns.includes(this.columns[i]);
      this.columns[i].show = show;
      this.navigation.setParamValue('posts.' + this.columns[i].field + '.showFiled', show);
    }
  }

  exportPdf() {
    ToolsService.exportPdf(this.articles, this.columns, 'Articles');
  }

  exportExcel() {
    ToolsService.exportExcel(this.articles, this.columns, 'Articles');
  }

  exportCsv() {
    ToolsService.exportCsv(this.articles, this.columns, 'Articles');
  }

  refresh() {
    this.getArticles();
  }

  articleAdd(): void {
    if (!this.navigation.getParamValue('posts.addInDialog', true)) {
      this.router.navigate(['/articles/add']);
      return;
    }
    this.articleAddDialog = this.dialogService.open(DialogArticleFormComponent, {
      header: '', // this.translate.instant('NEWARTICLE'),
      width: '80%',
      contentStyle: { 'height': '800px', overflow: 'auto' },
      baseZIndex: 1000,
      data: {
        mode: 'add',
        post: {id: null},
      },
    });

    this.articleAddDialog.onClose.subscribe((response: any) => {
      if (response && response.refresh) {
        this.messageService.clear();
        this.messageService.add({severity: 'success', summary: 'Success', detail: 'Article added'});
        this.getArticles();
      }
    });
  }

  articleEdit(article: Article): void {
    if (!this.navigation.getParamValue('posts.editInDialog', true)) {
      this.router.navigate(['/articles/edit/' + article.id]);
      return;
    }
    this.articleEditDialog = this.dialogService.open(DialogArticleFormComponent, {
      header: '', // this.translate.instant('EDITARTICLE') + ' ' + article.title,
      width: '70%',
      contentStyle: { 'height': '800px', overflow: 'auto' },
      baseZIndex: 1000,
      data: {
        mode: 'edit',
        post: article,
       // users: this.postsColumnsService.users,
      },
    });

    this.articleEditDialog.onClose.subscribe((response: any) => {
      if (response && response.refresh) {
        this.messageService.clear();
        this.messageService.add({severity: 'success', summary: 'Success', detail: 'article updated'});
        this.getArticles();
      }
    });
  }

  articleDetails(article: Article): void {
    if (!this.navigation.getParamValue('posts.detailsInDialog', true)) {
      this.router.navigate(['/articles/details/' + article.id]);
      return;
    }
    this.articleDetailsDialog = this.dialogService.open(DialogArticleDetailsComponent, {
      width: '70%',
      contentStyle: { 'height': '800px', overflow: 'auto' },
      baseZIndex: 1000,
      data: {
        post: article,
      }
    });
  }

  articleDelete(article: Article): void {
    this.articlesToDelete = article;
    this.messageService.clear();
    this.messageService.add({
      key: 'postDelete',
      sticky: true,
      severity: 'warn',
      summary: 'Are you sure you want delete ' + article.title + ' ?',
      detail: 'Confirm to proceed'
    });
  }

  onDeleteConfirm(): void {
    this.articlesDataService.deleteArticle(this.articlesToDelete).subscribe((res: any) => {
      this.messageService.clear();
      this.messageService.add({severity: 'success', summary: 'Success', detail: 'Article deleted'});
      this.getArticles();
    }, (error: any) => {
      this.messageService.clear();
      this.messageService.add({severity: 'error', summary: 'Error', detail: 'Article not deleted'});
    });
  }

  onDeleteReject(): void {
    this.articlesToDelete = {};
    this.messageService.clear('postDelete');
  }

}
