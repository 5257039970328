import { INavData } from '../coreui/sidebar';

export const WalkAndWinNavItems: INavData[] = [
  {
    name: 'DASHBOARD.DASHBOARD',
    url: '/dashboard',
    icon: 'sli-speedometer',
    badge: {
      variant: 'info',
      text: 'NEW'
    }
  },
  {
    title: true,
    name: 'Walk and win Manager' 
  },
      {
        name: 'DASHBOARD.COMPETITIONS',
        url: '/competition',
        icon: 'sli-event'
      },
      {
        name: 'DASHBOARD.MEMBERS',
        url: '/members',
        icon: 'cil-running'
      },
      {
        name: 'DASHBOARD.GIFTS',
        url: '/gifts',
        icon: 'sli-calendar'
      },
      {
        name: 'DASHBOARD.CATEGORIES',
        url: '/categories',
        icon: 'sli-film'
      },
      {
   
        name: 'DASHBOARD.ARTICLES',
        url: '/articles',
        icon: 'cil-short-text'
      },
      {
   
        name: 'Top 50',
        url: '/list',
        icon: 'cil-short-text'
      },
      {
   
        name: 'Location',
        url: '/location',
        icon: 'cil-short-text'
      },

      {
   
        name: 'Statistics',
        url: '/statistics',
        icon: 'cil-short-text'
      },

      {
        name: 'AI Chat',
        // url: '/questions',
        icon: 'sli-bubbles', 
       
      },


  {
    name: 'AI Chat',
    // url: '/reviews',
    icon: 'sli-bubbles', // reviews
    children: [
      {
        name: 'DASHBOARD.QUESTIONS',
        url: '/questions',
        icon: 'sli-like'// likes
      },
      {
        name: 'DASHBOARD.ANSWERS',
        url: '/answers',
        icon: 'sli-heart'// favorites
      },
      {
        name: 'DASHBOARD.SUGGESTIONS',
        url: '/suggestions',
        icon: 'sli-bubbles'// reviews
      },
      {
        name: 'DASHBOARD.SCORES',
        url: '/scores',
        icon: 'sli-bubbles'// reviews
      },
    ]
  },
 
];

