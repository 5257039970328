import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { MessageService, ConfirmationService } from 'primeng/api';
import { Column } from '../../../interfaces/column.interface';
import { GiftsColumnsService } from '../../../services/gifts-columns.service';
import { Gift } from '../../../interfaces/gift.interface';

@Component({
  selector: 'app-gift-session-details',
  templateUrl: './gift-details.component.html',
  styleUrls: ['./gift-details.component.scss'],
  providers: [MessageService, ConfirmationService, DynamicDialogRef, DynamicDialogConfig],
})
export class GiftDetailsComponent implements OnInit, OnDestroy {

  public gift: Gift = {};
  public columns: Column[] = [];

  constructor(
    private router: Router,
    private dynamicDialogConfig: DynamicDialogConfig,
    private giftsColumnsService: GiftsColumnsService,

  ) { }

  ngOnInit(): void {
    if (this.dynamicDialogConfig.data && typeof this.dynamicDialogConfig.data.gift !== 'undefined') {
      this.gift = this.dynamicDialogConfig.data.gift;
    }
    this.columns = this.giftsColumnsService.getColumns();
    for (let i = 0; i <= this.columns.length; i++) {
      if (this.columns[i].field === "actions") {
        this.columns.splice(i, 1);
      }
    }
  }

  ngOnDestroy(): void {
  }

  list() {
    this.router.navigate(['/gifts']);
  }

  add() {
    this.router.navigate(['/gifts/add']);
  }

  edit() {
    this.router.navigate(['/gifts/edit/' + this.gift.id]);
  }

}
@Component({
  selector: 'app-gift-session-details',
  templateUrl: './gift-details.component.html',
  styleUrls: ['./gift-details.component.scss'],
  providers: [MessageService, ConfirmationService],
})
export class DialogGiftDetailsComponent extends GiftDetailsComponent {
}

