import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Gift, GiftsResult } from '../interfaces';
import { AuthService } from '../../services/auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class GiftsDataService {

  private uri: string = `${environment.apiUrl}gift`;
  private headers:any ;

  constructor(
    private http: HttpClient
  ) {
    const jsonAuth = localStorage.getItem(AuthService.ATUH_STORAGE_KEY);
    if (jsonAuth) {
      const token = JSON.parse(jsonAuth)?.token;
      if (token) {
        this.headers = new HttpHeaders({
          enctype: 'multipart/form-data',
          authorization: 'bearer ' + token,
        });
      } 
    }
  }

  getGifts(): Observable<GiftsResult|any> {
    return this.http.get(this.uri);
  }

  addGift(data: Gift): Observable<Gift|any> {
    return this.http.post(this.uri + '/add', data, { headers :this.headers });
  }

  updateGift(data: Gift, id:any): Observable<Gift|any> {
    return this.http.post(this.uri + '/update'+ `/${id}`, data, { headers :this.headers });
  }

  deleteGift(data: Gift): Observable<null|any> {
    return this.http.put(this.uri + "/update_active" + `/${data.id}`, "");
  }
}
