import { Component } from '@angular/core';
import { WalkAndWinNavItems } from '../../walkwin/walkwin.nav';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html'
})
export class DefaultLayoutComponent {
  public sidebarMinimized = false;
  public navItems = WalkAndWinNavItems.map(items => { this.translateNav(items); return items; });

  public locale: string | null = localStorage.getItem('locale') || 'fr';

  constructor(
    private translate: TranslateService
  ) {

  }

  toggleMinimize(e: any) {
    this.sidebarMinimized = e;
  }


  ngOnInit() {
    this.locale = localStorage.getItem('locale');
    this.translate.onLangChange.subscribe(() => {
      const translatedNavs = this.navItems.map(items => { this.translateNav(items); return items; });
      this.navItems = [];
      translatedNavs.forEach(val => this.navItems.push(Object.assign({}, val)));
    });
  }

  translateNav(item: any): void {
    const trans = this.translate.instant(`${item.name}`);
    item.name = trans;
    if (item.children && item.children.length > 0) {
      item.children.map((child: any) => this.translateNav(child));
    }
  }
}
