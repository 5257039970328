import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StatisticsService {

  private apiUrl = 'https://api-symfony.walkandwin.tn/points/stats';

  constructor(private http: HttpClient) { }

  getStatistics(min: number, max: number): Observable<any> {
    return this.http.post<any>(this.apiUrl, { min, max });
  }
}
