import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class NavigationService {

    public booleanOptions = [{
        id: '',
        title: 'ALL',
        css: ''
    }, {
        id: '1',
        title: 'YES',
        css: 'success'
    }, {
        id: '0',
        title: 'NO',
        css: 'danger'
    }];

    constructor(
    ) {
    }

    getParamValue(key: string, defaultValue: any): any {
        const jsonValue = localStorage.getItem(key);
        if (jsonValue !== null) {
            return JSON.parse(jsonValue);
        } else {
            localStorage.setItem(key, JSON.stringify(defaultValue));
            return defaultValue;
        }
    }

    setParamValue(key: string, value: any): void {
        localStorage.setItem(key, JSON.stringify(value));
    }
}
