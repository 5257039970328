import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AuthService } from '../services/auth/auth.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

    constructor(public auth: AuthService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add authorization header with jwt token if available

       const jsonAuth = localStorage.getItem(AuthService.ATUH_STORAGE_KEY);
        //  const jsonAuth=sessionStorage.getItem("auth");
        if (jsonAuth != null) {
            const data = JSON.parse(jsonAuth);
            request = request.clone({
                setHeaders: {
                    Authorization: 'Bearer ' + JSON.parse(jsonAuth).token
                }
            });
        }
        return next.handle(request).pipe(
            map((event) => {
                return event;
            }),
            catchError(
                (
                    httpErrorResponse: HttpErrorResponse,
                    _: Observable<any>
                ) => {
                    if (httpErrorResponse.status === 401 || httpErrorResponse.status === 403) {
                        localStorage.removeItem(AuthService.ATUH_STORAGE_KEY);
                        this.auth.redirectToLogin('/login', {});
                    }
                    return throwError(httpErrorResponse);
                }
            )
        );
    }
}
