import { Component, OnInit } from '@angular/core';
import { DashboardService } from '../../services/dashboard.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  public statistics = [
    {
      label: 'Members',
      entity: 'User',
      bg: 'info',
      icon: 'cil-running',
      count: 0,
      url: '/members',
    }, {
      label: 'Competitions',
      entity: 'Competition',
      bg: 'warning',
      icon: 'sli-event',
      count: 0,
      url: '/competition',
    }, {
      label: 'Gifts',
      entity: 'Gift',
      bg: 'purple',
      icon: 'sli-calendar',
      count: 0,
      url: '/gifts',
    }, {
      label: 'Categories',
      entity: 'Category',
      bg: 'danger',
      icon: 'sli-film',
      count: 0,
      url: '/categories',
    },
    {
      label: 'DASHBOARD.Articles',
      entity: 'Article',
      bg: 'indigo',
      icon: 'cil-newspaper',
      count: 0,
      url: '/articles',
    },
    // {
    //   label: 'Notifications',
    //   entity: 'PushNotification',
    //   bg: 'secondary',
    //   icon: 'cil-voice-over-record',
    //   count: 0,
    //   url: '/notification',
    // }, {
    //   label: 'Devices',
    //   entity: 'PushDevice',
    //   bg: 'success',
    //   icon: 'cil-weightlifitng',
    //   count: 0,
    //   url: '/devices',
    // }
  ];

  constructor(
    private dashboardService: DashboardService,
    private titleService: Title
  ) {
  }

  ngOnInit(): void {
    this.titleService.setTitle('Dashboard - Management');
    this.dashboardService.countTotalData().subscribe((response: any) => {
      this.statistics[0].count = response.success.users;
      this.statistics[1].count = response.success.competitions;
      this.statistics[2].count = response.success.gifts;
      this.statistics[3].count = response.success.categories;
      this.statistics[4].count = response.success.articles;
      //this.statistics[5].count = response.success.notification;
      //this.statistics[6].count = response.success.pushDevices;
    });
  }

}
