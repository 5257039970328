import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Member, MembersResult } from '../interfaces';
import { AuthService } from '../../services/auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class MembersDataService {

  private uri: string = `${environment.apiUrl}useruser`;

  constructor(
    private http: HttpClient,
    public auth: AuthService
  ) {

  }
  
  getMembers(): Observable<MembersResult|any> {
    const jsonAuth = localStorage.getItem(AuthService.ATUH_STORAGE_KEY);
    if (jsonAuth != null) {
      const headers = new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${JSON.parse(jsonAuth).token}`,
      });
    }

    return this.http.get(this.uri);
  }

}
