import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Competition, CompetitionResult } from '../interfaces';

@Injectable({
  providedIn: 'root'
})
export class CompetitionsDataService {

  private uri: string = `${environment.apiUrl}competition`;

  constructor(
    private http: HttpClient
  ) {}

  getCompetitions(): Observable<CompetitionResult|any> {
    return this.http.get(this.uri);
  }

  addCompetition(data: Competition): Observable<Competition|any> {
    const cleanData = Object.assign({}, data);
    delete cleanData.id;
    return this.http.post(this.uri + "/add", cleanData);
  }

  updateCompetition(data: Competition, id:any): Observable<Competition|any> {
    const cleanData = Object.assign({}, data);
    delete cleanData.id;
    return this.http.post(this.uri + "/update" + `/${id}`, cleanData);
  }

  deleteCompetition(data:any): Observable<null|any> {
    return this.http.post(this.uri + "/update_active" + `/${data.id}`, "");
  }


}
