<ng-template [ngIf]="mobileSidebarToggler != false">
  <button class="navbar-toggler {{sidebarTogglerMobileClass}}" type="button" appSidebarToggler>
    <span class="navbar-toggler-icon"></span>
  </button>
</ng-template>
<a class="navbar-brand" [routerLink]="navbarBrandRouterLink">
  <ng-template [ngIf]="navbarBrandImg">
    <img *ngIf="navbarBrand"
         [appHtmlAttr]="navbarBrand"
         [ngClass]="'navbar-brand'">
    <img *ngIf="navbarBrandFull"
         [appHtmlAttr]="navbarBrandFull"
         [ngClass]="'navbar-brand-full'">
    <img *ngIf="navbarBrandMinimized"
         [appHtmlAttr]="navbarBrandMinimized"
         [ngClass]="'navbar-brand-minimized'">
  </ng-template>
  <ng-template [ngIf]="!navbarBrandImg">
    <div class="navbar-brand-full" [innerHTML]="navbarBrandText.text"></div>
    <div class="navbar-brand-minimized" [innerHTML]="navbarBrandText.icon"></div>
  </ng-template>
</a>
<ng-template [ngIf]="sidebarToggler">
  <button class="navbar-toggler {{sidebarTogglerClass}}" type="button" [appSidebarToggler]="sidebarToggler">
    <span class="navbar-toggler-icon"></span>
  </button>
</ng-template>
<ng-content></ng-content>

<ng-template [ngIf]="mobileAsideMenuToggler != false">
  <button class="navbar-toggler {{asideTogglerMobileClass}}" type="button" appAsideMenuToggler>
    <span class="navbar-toggler-icon"></span>
  </button>
</ng-template>
