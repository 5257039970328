<div class="animated fadeIn">
  <div class="row">
    <div *ngFor="let statistic of statistics" class="col-sm-6 col-lg-3">
      <div class="card text-white bg-{{ statistic.bg }}">
        <div class="card-body pb-0">
          <button type="button" [routerLink]="statistic.url" class="btn btn-transparent p-0 float-right">
            <i class="h2 {{ statistic.icon }}"></i>
          </button>
          <div class="text-value">{{ statistic.count }} </div>
          <div>{{ statistic.label | translate }} </div>
        </div>
      </div>
    </div>
    <!--/.col-->
  </div>
    
  <!--/.row-->
</div>