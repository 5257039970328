

import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';

// import Quill from 'quill';
// import { ImageResize } from 'quill-image-resize';
// import htmlEditButton from 'quill-html-edit-button';
// Quill.register('modules/imageResize', ImageResize);
// Quill.register('modules/htmlEditButton', htmlEditButton);



@Injectable({
  providedIn: 'root'
})
export class EditorService {

  public config: any = {
    toolbar: {
      items: [
        'undo',
        'redo',
        '|',
        'heading',
        'fontFamily',
        'fontSize',
        '|',
        'bold',
        'italic',
        'underline',
        'fontColor',
        'fontBackgroundColor',
        'highlight',
        '|',
        'link',
        'CKFinder',
        'imageUpload',
        'mediaEmbed',
        '|',
        'alignment',
        'bulletedList',
        'numberedList',
        '|',
        'indent',
        'outdent',
        '|',
        'insertTable',
        'blockQuote',
        'specialCharacters',
      ],
      shouldNotGroupWhenFull: true
    }
  };

  constructor(
  ) {

  }

}
