import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { CacheService } from '../../services/cache/cache.service';
import { List, ListResult } from '../interfaces';

@Injectable({
  providedIn: 'root'
})
export class ListDataService {

  private uri: string = `${environment.apiUrl}user/get-users-points`;

  constructor(
    private http: HttpClient
  ) {

  }

  getList(parameters: {[param: string]:  string | string|string | string[]}): Observable<ListResult|any> {
    return this.http.get(this.uri + '/' + parameters.month + '/' + parameters.year);
  }

}
