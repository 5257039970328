import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { MessageService, ConfirmationService, PrimeNGConfig } from 'primeng/api';
import { Title } from '@angular/platform-browser';
import { NavigationService } from '../../../services/navigation/navigation.service';
import { ToolsService } from '../../../services/tools/tools.service';
import { Column } from '../../interfaces/column.interface';
import { CompetitionsDataService, CompetitionsColumnsService } from '../../services';
import { DialogCompetitionFormComponent } from './competition-form/competition-form.component';
import { DialogCompetitionDetailsComponent } from './competition-details/competition-details.component';
import { CompetitionResult, Competition } from '../../interfaces';

@Component({
  selector: 'app-competition',
  templateUrl: './competition.component.html',
  styleUrls: ['./competition.component.scss'],
  providers: [MessageService, ConfirmationService, DialogService],
})
export class competitionsComponent implements OnInit, OnDestroy {

  private CompetitionAddDialog: DynamicDialogRef = new DynamicDialogRef();
  private competitionEditDialog: DynamicDialogRef = new DynamicDialogRef();
  private competitionDetailsDialog: DynamicDialogRef = new DynamicDialogRef();
  public CompetitionToDelete: Competition = {};
  public Competitions: Competition[] = [];
  public columns: Column[] = [];
  public isLoading: boolean = false;
  public isInitialized: boolean = false;
  private _selectedColumns: Column[] = [];

  constructor(
    private translate: TranslateService,
    private navigation: NavigationService,
    private router: Router,
    private dialogService: DialogService,
    private messageService: MessageService,
    private primengConfig: PrimeNGConfig,
    private titleService: Title,
    private competitionsDataService: CompetitionsDataService,
    private competitionsColumnsService: CompetitionsColumnsService,
  ) {
  }

  ngOnInit(): void {
    this.isInitialized = true;
    this.titleService.setTitle('Competition - Management');
    this.primengConfig.ripple = true;
    this.getCompetitions();
  }

  ngOnDestroy(): void {
    this.isInitialized = false;
  }

  getCompetitions(): void {
    this.isLoading = true;
    if (!this.isInitialized) {
      return;
    }
    this.competitionsDataService.getCompetitions().subscribe((response: CompetitionResult|any) => {
      this.isLoading = false;
      if (response !== null) {
        this.setColumns();
        this.Competitions = response.success.data;
      }
    }, (error: any) => {;
      this.isLoading = false;
      this.messageService.add({severity: 'error', summary: this.translate.instant('UI.ERROR'), detail: this.translate.instant('UI.COMPETITIONSNOTLOADED')});
    });
  }

  setColumns() {
    if (this.columns.length > 0) {
      return;
    }
    this.columns = this.competitionsColumnsService.getColumns();
    for (let i = 0; i < this.columns.length; i++) {
      if (this.columns[i].show) {
        this._selectedColumns.push(this.columns[i]);
      }
    }
  }

  @Input()
  get selectedColumns(): any[] {
      return this._selectedColumns;
  }

  set selectedColumns(val: any[]) {
      // restore original order
      this._selectedColumns = this.columns.filter((col: any) => val.includes(col));
      this.updateColumns();
  }

  updateColumns() {
    for (let i = 0; i < this.columns.length; i++) {
      const show = this._selectedColumns.includes(this.columns[i]);
      this.columns[i].show = show;
      this.navigation.setParamValue('competitionReservations.' + this.columns[i].field + '.showFiled', show);
    }
  }

  exportPdf() {
    ToolsService.exportPdf(this.Competitions, this.columns, 'Competitions');
  }

  exportExcel() {
    ToolsService.exportExcel(this.Competitions, this.columns, 'Competitions');
  }

  exportCsv() {
    ToolsService.exportCsv(this.Competitions, this.columns, 'Competitions');
  }

  refresh() {
    this.getCompetitions();
  }

  competitionAdd(): void {
    if (!this.navigation.getParamValue('competitions.addInDialog', true)) {
      this.router.navigate(['/competition/add']);
      return;
    }
    this.CompetitionAddDialog = this.dialogService.open(DialogCompetitionFormComponent, {
      header: '', 
      width: '80%',
      contentStyle: { 'height': '800px', overflow: 'auto' },
      baseZIndex: 1000,
      data: {
        mode: 'add',
        competition: {id: null},
      },
    });

    this.CompetitionAddDialog.onClose.subscribe((response: any) => {
      if (response && response.refresh) {
        this.messageService.clear();
        this.messageService.add({severity: 'success', summary: 'Success', detail: 'Competition added'});
        this.getCompetitions();
      }
    });
  }

  competitionEdit(Competition: Competition): void {
    if (!this.navigation.getParamValue('competitions.editInDialog', true)) {
      this.router.navigate(['/competition/edit/' + Competition.id]);
      return;
    }
    this.competitionEditDialog = this.dialogService.open(DialogCompetitionFormComponent, {
      header: '',
      width: '70%',
      contentStyle: { 'height': '800px', overflow: 'auto' },
      baseZIndex: 1000,
      data: {
        mode: 'edit',
        competition: Competition,
      
      },
    });

    this.competitionEditDialog.onClose.subscribe((response: any) => {
      if (response && response.refresh) {
        this.messageService.clear();
        this.messageService.add({severity: 'success', summary: 'Success', detail: 'Competition updated'});
        this.getCompetitions();
      }
    });
  }

  competitionDetails(competition: Competition): void {
    if (!this.navigation.getParamValue('competitions.detailsInDialog', true)) {
      this.router.navigate(['/competition/details/' + competition.id]);
      return;
    }
    this.competitionDetailsDialog = this.dialogService.open(DialogCompetitionDetailsComponent, {
      width: '70%',
      contentStyle: { 'height': '800px', overflow: 'auto' },
      baseZIndex: 1000,
      data: {
        competition: competition,
      }
    });
  }

  competitionDelete(competition: Competition): void {
    this.CompetitionToDelete = competition;
    this.messageService.clear();
    this.messageService.add({
      key: 'competitionDelete',
      sticky: true,
      severity: 'warn',
      summary: 'Are you sure you want delete ' + competition.name + ' ?',
      detail: 'Confirm to proceed'
    });
  }

  onDeleteConfirm(): void {
    this.competitionsDataService.deleteCompetition(this.CompetitionToDelete).subscribe((res: any) => {
      this.messageService.clear();
      this.messageService.add({severity: 'success', summary: 'Success', detail: 'Competition deleted'});
      this.getCompetitions();
    }, (error: any) => {
      this.messageService.clear();
      this.messageService.add({severity: 'error', summary: 'Error', detail: 'Competition not deleted'});
    });
  }

  onDeleteReject(): void {
    this.CompetitionToDelete = {};
    this.messageService.clear('competitionDelete');
  }

}
