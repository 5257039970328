import { Component, OnInit } from '@angular/core';
import { StatisticsService } from './../../services/statistics.service';

@Component({
  selector: 'app-stats',
  templateUrl: './stats.component.html',
  styleUrls: ['./stats.component.scss']
})
export class StatsComponent implements OnInit {
  min: number = 0;
  max: number = 10000;
  statistics: any;
  public selectedYear: any;
  selectedMonth: any = '11'; // Par défaut, le mois d'octobre
  tableData: { month: string, points: any[] }[] = [];

  years: string[] = [];
  months: string[] = [];
  monthss: string[] = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];

  monthNames: { [key: string]: string } = {
    '01': 'Janvier',
    '02': 'Février',
    '03': 'Mars',
    '04': 'Avril',
    '05': 'Mai',
    '06': 'Juin',
    '07': 'Juillet',
    '08': 'Août',
    '09': 'Septembre',
    '10': 'Octobre',
    '11': 'Novembre',
    '12': 'Décembre',

  };
  chartData: any[] = [];
  chartLabels: string[] = [];
  chartOptions: any = {
    responsive: true,
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            max: 40
          }
        }
      ]
    }
  };
  chartLegend: boolean = true;
  chartType: any = 'bar';


  pointLabels: string[] = [];
  tableDatatable: { year: string, months: { month: string, points: number[] }[] }[] = [];

  constructor(private statisticsService: StatisticsService) { }

  ngOnInit(): void {
    
    this.onSubmit();
  }

  onSubmit(): void {
    this.statisticsService.getStatistics(this.min, this.max).subscribe(response => {
      this.statistics = response.success;
      this.formatTableData();

      this.years = Object.keys(this.statistics);
      if (this.years.length > 0) {
        this.selectedYear = this.years[0];
        // this.updateChartData();

      }
    });
  }

  onYearChange(event: any): void {
    this.selectedYear = event.target.value;
  }
  onMonthChange(event: any): void {
    this.selectedMonth = event.target.value;
    this.updateChartData();
  }
  updateChartData(): void {
    const data = this.statistics['2023'][this.selectedMonth];
    const points = Object.keys(data).map(key => parseInt(key.split('_')[0]));
    const values = Object.values(data);
    this.chartLabels = points.map(point => point.toString());
    this.chartData = [{ data: values, label: 'Nombre de personnes' }];
  }

  updateTableData(): void {
    this.tableData = this.months.map(month => {
      const monthData = this.statistics['2023'][month];
      const points = Object.keys(monthData).map(key => {
        return { point: key.split('_')[0], count: monthData[key] };
      });
      return { month: this.monthNames[month], points };
    });
  }



  formatTableData(): void {
    this.tableDatatable = [];
    this.years = Object.keys(this.statistics);
    this.pointLabels = Object.keys(this.statistics[this.years[0]][Object.keys(this.statistics[this.years[0]])[0]]);

    for (const year of this.years) {
      const monthsData = this.statistics[year];
      const formattedMonths = Object.keys(monthsData).map(month => {
        const monthData = monthsData[month];
        const points = this.pointLabels.map(point => monthData[point] || 0);
        return { month, points };
      });
      this.tableDatatable.push({ year, months: formattedMonths });
    }
  }
}
