import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { MessageService, ConfirmationService } from 'primeng/api';
import moment from 'moment';
import { CommonService } from '../../../../services/common/common.service';
import { Gift, GiftsResult } from '../../../interfaces/gift.interface';
import { GiftsDataService } from '../../../services/gifts-data.service';
import { GiftsColumnsService } from '../../../services/gifts-columns.service';

@Component({
  selector: 'app-gift-session-form',
  templateUrl: './gift-form.component.html',
  styleUrls: ['./gift-form.component.scss'],
  providers: [MessageService, ConfirmationService, DynamicDialogRef, DynamicDialogConfig],
})
export class GiftFormComponent implements OnInit {

  public gift: Gift = {};
  public giftForm: FormGroup = new FormGroup({});
  public gifts: Gift[] = [];
  public isLoading: boolean = false;
  public redirect = true;
  public disableSubmit = false;
  selectedFile: File | null;
  formData = new FormData();

  //imagePath = new FormControl(this.gift.imagePath, []);


  constructor(
    private translate: TranslateService,
    public common: CommonService,
    private router: Router,
    private messageService: MessageService,
    private dynamicDialogConfig: DynamicDialogConfig,
    private dynamicDialogRef: DynamicDialogRef,
    private giftsDataService: GiftsDataService,
    public giftColumnsService: GiftsColumnsService,
  ) {
    this.selectedFile = null; // Initialize it to a default value, such as null
  }

  ngOnInit(): void {
    if (this.dynamicDialogConfig.data && typeof this.dynamicDialogConfig.data.gift !== 'undefined') {
      this.gift = this.dynamicDialogConfig.data.gift;
    }
    if (typeof this.gift.id === 'undefined' || this.gift.id === null) {
      this.gift.id = null;
      this.gift.creationDate = moment().set({minute: 0, second: 0, millisecond: 0}).format('YYYY-MM-DD\THH:mm:ssZ');
      this.gift.active = false;
    }
    this.getGifts();
    this.prepareFrom();
  }

  getGifts(): void {
    if (this.dynamicDialogConfig.data && typeof this.dynamicDialogConfig.data.gift !== 'undefined' && this.dynamicDialogConfig.data.gift.length > 0) {
      this.gifts = this.dynamicDialogConfig.data.gift;
      return;
    }
    this.giftsDataService.getGifts().subscribe((response: GiftsResult|any) => {
      this.isLoading = false;
      if (response !== null) {
        this.gifts = response.results;
      }
    });
  }

  prepareFrom(): void {
   // this.gift.creationDate = moment(this.gift.creationDate).toDate();
    this.giftForm = new FormGroup({
      id: new FormControl(this.gift.id, []),
      nameFr: new FormControl(this.gift.nameFr, [Validators.required, ]),
      nameAr: new FormControl(this.gift.nameAr, []),
    
      description: new FormControl(this.gift.description, []),
      descriptionAr: new FormControl(this.gift.descriptionAr, []),
      descriptionFr: new FormControl(this.gift.descriptionFr, []),
      requiredPointsNumber: new FormControl(this.gift.requiredPointsNumber, []),
      value: new FormControl(this.gift.value, []),
      imagePath: new FormControl(this.gift.imagePath, []),
    });
  }

  list() {
    this.router.navigate(['/gifts']);
  }

  showErrors() {
      const invalidFields: string[] = [];
      Object.keys(this.giftForm.controls).forEach((field: any) => {
        const control = this.giftForm.get(field);
        if (control !== null) {
          control.markAsTouched({ onlySelf: true });
          if (control.invalid) {
            invalidFields.push(field);
          }
        }
      });
  }

  submitForm(redirect: boolean) {
    this.redirect = redirect;
    if (!this.giftForm.valid) {
      this.showErrors();
    } else {
      this.saveData(redirect);
    }
  }

  saveData(redirect: boolean) {
    this.gift = this.giftForm.value;
    this.disableSubmit = true;
    this.formData.append('name', this.giftForm ? this.giftForm.value.nameFr : null);
    this.formData.append('nameFr', this.giftForm ? this.giftForm.value.nameFr : null);
    this.formData.append('nameAr', this.giftForm ? this.giftForm.value.nameAr : null);
    this.formData.append('description', this.giftForm ? this.giftForm.value.description : null);
    this.formData.append('descriptionAr', this.giftForm ? this.giftForm.value.descriptionAr : null);
    this.formData.append('descriptionFr', this.giftForm ? this.giftForm.value.descriptionFr : null);
    this.formData.append('requiredPointsNumber', this.giftForm ? this.giftForm.value.requiredPointsNumber : null);
    this.formData.append('value', this.giftForm ? this.giftForm.value.value : null);

    if (this.gift.id === null) {
      this.add(redirect, this.formData);
    } else {
      this.update(redirect, this.formData);
    }
    return false;
  }

  uploadPhoto(event: any): void {
    this.selectedFile = event.target.files[0] as File;
    if (this.selectedFile) {
      this.formData.append('picture', this.selectedFile);
    }
  }

  add(redirect: boolean, data:any) {
    this.giftsDataService.addGift(data).subscribe((data: any) => {
      this.disableSubmit = false;
      if (redirect && typeof this.dynamicDialogConfig.data !== 'undefined') {
        this.dynamicDialogRef.close({refresh: true});
      } else if (redirect) {
        this.list();
      }
    }, (error: any) => {
      this.disableSubmit = false;
      console.warn(error);
    });
  }

  update(redirect: boolean, data:any) {
    this.giftsDataService.updateGift(data, this.giftForm.value.id).subscribe((data: any) => {
        this.disableSubmit = false;
        if (redirect && typeof this.dynamicDialogConfig.data !== 'undefined') {
          this.dynamicDialogRef.close({refresh: true});
        } else if (redirect) {
          this.list();
        }
      }, (error: any) => {
        this.disableSubmit = false;
        console.warn(error);
    });
  }

}


@Component({
  selector: 'app-gift-session-form',
  templateUrl: './gift-form.component.html',
  styleUrls: ['./gift-form.component.scss'],
  providers: [MessageService, ConfirmationService],
})
export class DialogGiftFormComponent extends GiftFormComponent {
}

