import { Component, OnInit } from '@angular/core';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { MessageService, ConfirmationService } from 'primeng/api';
import { MembersColumnsService } from '../../../services';
import { Member } from '../../../interfaces';
import { Column } from '../../../interfaces/column.interface';

@Component({
  selector: 'app-member-details',
  templateUrl: './member-details.component.html',
  styleUrls: ['./member-details.component.scss'],
  providers: [MessageService, ConfirmationService, DynamicDialogRef, DynamicDialogConfig],
})
export class MemberDetailsComponent implements OnInit {

  public member: Member = {};
  public columns: Column[] = [];

  constructor(
    private dynamicDialogConfig: DynamicDialogConfig,
    private membersColumnsService: MembersColumnsService,

  ) { }

  ngOnInit(): void {
    if (this.dynamicDialogConfig.data && typeof this.dynamicDialogConfig.data.member !== 'undefined') {
      this.member = this.dynamicDialogConfig.data.member;
    }
    this.columns = this.membersColumnsService.getColumns();
  }

}
@Component({
  selector: 'app-member-details',
  templateUrl: './member-details.component.html',
  styleUrls: ['./member-details.component.scss'],
  providers: [MessageService, ConfirmationService],
})
export class DialogMemberDetailsComponent extends MemberDetailsComponent {
}

