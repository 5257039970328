import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NavigationService } from '../../services/navigation/navigation.service';
import { CacheService } from '../../services/cache/cache.service';
import { CommonService } from '../../services/common/common.service';
import { Column } from '../interfaces/column.interface';
import moment from 'moment';
import { User, UsersResult } from '../interfaces';

@Injectable({
  providedIn: 'root'
})
export class ListColumnsService {

  public users: User[] = [];

  constructor(
    private translate: TranslateService,
    private navigation: NavigationService
  ) {
  }

  getColumns(): Column[] {
    return [
      // { field: 'id', sortField: 'id', title: this.translate.instant('ID'), show: (this.navigation.getParamValue('posts.id.showFiled', true) && true), displayInList: true, type: 'numericValue' },
      { field: 'phoneNumber', sortField: 'phoneNumber', title: this.translate.instant('CONTENT.PHONE'), show: (this.navigation.getParamValue('posts.phoneNumber.showFiled', true) && true), displayInList: true, type: 'textValue' },
      { field: 'numberOfPoints', sortField: 'numberOfPoints', title: this.translate.instant('CONTENT.POINTS'), show: (this.navigation.getParamValue('posts.numberOfPoints.showFiled', true) && true), displayInList: true, type: 'numericValue' },

      // { field: 'date', sortField: 'date', title: this.translate.instant('CREATEDAT'), show: (this.navigation.getParamValue('posts.creationDate.showFiled', false) && true), displayInList: true, type: 'dateValue', valueFormatter: this.translate.instant('DATETIMEFORMAT') },
    ];
  }

}
