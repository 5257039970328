import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NavigationService } from '../../services/navigation/navigation.service';
import { Column } from '../interfaces/column.interface';
import { Device, Member } from '../interfaces';
import { Notification } from '../interfaces';
import { User } from '../interfaces';

@Injectable({
  providedIn: 'root'
})
export class QuestionsColumnsService {

  public users: User[] = [];
  public members:Member[]=[];

  constructor(
    private translate: TranslateService,
    private navigation: NavigationService,

  ) {
  }

  getColumns(): Column[] {
    return [
      { field: 'title', sortField: 'title', title: this.translate.instant('CONTENT.DESCRIPTION'), show: (this.navigation.getParamValue('question.title.showFiled', false) && true), displayInList: true, type: 'textValue'},
      { field: 'titleFr', sortField: 'titleFr', title: this.translate.instant('CONTENT.DESCRIPTIONFR'), show: (this.navigation.getParamValue('question.titleFr.showFiled', false) && true), displayInList: true, type: 'textValue'},
      { field: 'titleAr', sortField: 'titleAr', title: this.translate.instant('CONTENT.DESCRIPTIONAR'), show: (this.navigation.getParamValue('question.titleAr.showFiled', false) && true), displayInList: true, type: 'textValue'},
      { field: 'points', sortField: 'points', title: this.translate.instant('CONTENT.REQUIREDNUMBEROFPOINTS'), show: (this.navigation.getParamValue('question.points.showFiled', false) && true), displayInList: true, type: 'textValue'},
      
      { field: 'actions', title: this.translate.instant('CONTENT.ACTIONS'), type: 'static', show: true, displayInList: true, sortField: null},
    ];
  }


}
