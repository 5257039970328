<div class="animated fadeIn">
    <div class="d-flex align-items-center">
        <h1>Top 50</h1>
    </div>
    <div class="d-flex align-items-center">

        <form class="w-100" pFocusTrap name="dateForm" id="dateForm" novalidate (ngSubmit)="submitForm(true)"
            [formGroup]="dateForm">

            <div class="row">
                <div class="col-6">
                    <div class="input-group mb-3">
                        <input id="month" type="month" name="month" required formControlName="month" class="form-control" placeholder="month" aria-label="month" aria-describedby="basic-addon1">
                      </div>
                </div>
                <div class="col-6">
                    <input class="w-25" type="submit"  class="btn btn-primary" value="Afficher Liste" />
                </div>
            </div>
        </form>
    </div>

    
    <div class="card" *ngIf="list.length > 0">
        <p-toolbar styleClass="p-mb-4">
            <ng-template pTemplate="right">
                <button type="button" pButton pRipple *ngIf="list.length > 0" icon="pi pi-file-excel"
                    class="p-button-success p-mr-2" (click)="exportExcel()" label="{{ 'TOOLS.EXPORTXLS' | translate }}">
                </button>
                <button type="button" pButton pRipple *ngIf="list.length > 0" icon="pi pi-file-pdf"
                    class="p-button-warning p-mr-2" (click)="exportPdf()"
                    label="{{ 'TOOLS.EXPORTPDF' | translate }}"></button>
            </ng-template>
        </p-toolbar>

        <p-table #dt [value]="list" [rows]="10" [rowsPerPageOptions]="[10,25,50,100]" [paginator]="true"
            [columns]="selectedColumns" [resizableColumns]="false" [(selection)]="selectedArticles" [rowHover]="true"
            dataKey="id" [loading]="isLoading" stateStorage="local" stateKey="list"
            styleClass="p-datatable-striped p-datatable-gridlines p-datatable-responsive"
            [globalFilterFields]="['id','postType.name','title','titleAr','titleFr','slug','slugAr','slugFr','picture','content','contentAr','contentFr','isHeadline','autoPublishing','startPublishing','endPublishing','publishDate','metaTitle','metaDescription','metaKeywords','status','totalPrints','totalHits','totalComments','totalRatings','averageRatings','totalLikes','totalDislikes','totalBookmarks','isTop','isNew','ordering','createdAt','creatorUser.username','modifiedAt','modifierUser.username',]"
            currentPageReportTemplate="{{ 'TOOLS.PAGING' | translate }}" [showCurrentPageReport]="true"
            selectionMode="multiple">
            <ng-template pTemplate="caption">
                <div class="p-d-flex p-ai-center p-jc-between">
                    <span class="p-input-icon-left">
                        <i class="pi pi-search"></i>
                        <input pInputText type="text"
                            (input)="dt.filterGlobal(($event.target)?$event.target['value']:'', 'contains')"
                            placeholder="Search..." />
                    </span>
                </div>
            </ng-template>
            <ng-template pTemplate="header" let-columns>
                <tr *ngIf="columns.length > 0">
                    <th style="width: 3rem">
                        <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                    </th>
                    <ng-container *ngFor="let col of columns">
                        <th *ngIf="col.field !== 'actions'" [hidden]="!col.show" pSortableColumn="{{col.sortField}}">
                            <div class="p-d-flex p-jc-between p-ai-center">
                                {{ col.title }}
                                <p-sortIcon field="name"></p-sortIcon>
                                <p-columnFilter type="text" [field]="col.field" display="menu"
                                    *ngIf="col.type === 'textValue'">
                                </p-columnFilter>
                                <p-columnFilter type="text" [field]="col.field" display="menu"
                                    *ngIf="col.type === 'arrayValue'">
                                </p-columnFilter>
                                <p-columnFilter type="numeric" [field]="col.field" display="menu"
                                    *ngIf="col.type === 'numericValue'">
                                </p-columnFilter>
                                <p-columnFilter [field]="col.filterField" display="menu"
                                    *ngIf="col.type === 'linkValue'" matchMode="in" [showMatchModes]="false"
                                    [showOperator]="false" [showAddButton]="false">
                                    <ng-template pTemplate="header">
                                        <div class="p-px-3 p-pt-3 p-pb-0">
                                            <span class="p-text-bold">{{ col.title }} Picker</span>
                                        </div>
                                    </ng-template>
                                    <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                        <p-multiSelect [ngModel]="value" [options]="col.filterData" placeholder="Any"
                                            (onChange)="filter($event.value)" optionLabel="{{col.displayField}}"
                                            optionValue="id">
                                            <ng-template let-option pTemplate="item">
                                                <div class="p-multiselect-representative-option">
                                                    <span class="p-ml-1">{{option[col.displayField]}}</span>
                                                </div>
                                            </ng-template>
                                        </p-multiSelect>
                                    </ng-template>
                                </p-columnFilter>
                                <p-columnFilter type="date" [field]="col.field" display="menu"
                                    *ngIf="col.type === 'dateValue'">
                                </p-columnFilter>
                                <p-columnFilter type="boolean" [field]="col.field" display="menu"
                                    *ngIf="col.type === 'booleanValue'">
                                </p-columnFilter>
                                <p-columnFilter [field]="col.field" display="menu" *ngIf="col.type === 'enumValue'"
                                    matchMode="equals">
                                    <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                        <p-dropdown [ngModel]="value" [options]="col.filterData"
                                            (onChange)="filter($event.value)" placeholder="Any">
                                            <ng-template let-option pTemplate="item">
                                                <span [class]="'p-2 bg-' + option.css">{{ option.label | translate
                                                    }}</span>
                                            </ng-template>
                                        </p-dropdown>
                                    </ng-template>
                                </p-columnFilter>
                            </div>
                        </th>
                        <th *ngIf="col.field === 'actions'">{{ col.title }}</th>
                    </ng-container>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-columns="selectedColumns" let-article>
                <tr *ngIf="selectedColumns.length > 0">
                    <td>
                        <p-tableCheckbox [value]="article"></p-tableCheckbox>
                    </td>
                    <td *ngFor="let col of selectedColumns" [hidden]="!col.show">
                        <ng-container *ngIf="col.field === 'actions'">

                        </ng-container>
                        <ng-container *ngIf="col.field !== 'actions'">
                            <app-table-cell-value [value]="article[col.field]" [column]="col"></app-table-cell-value>
                        </ng-container>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="summary">
                <div class="p-d-flex p-ai-center p-jc-between">
                    {{ 'ACTIONS.INTOTAL' | translate }} {{list ? list.length : 0 }} {{ 'ARTICLE.ARTICLE' | translate }}.
                </div>
            </ng-template>
        </p-table>
    </div>



    <p-confirmDialog header="{{ 'UI.CONFIRMATION' | translate }}" icon="pi pi-exclamation-triangle"></p-confirmDialog>
    <p-scrollTop target="parent"></p-scrollTop>
</div>
<!--/.fadeIn-->