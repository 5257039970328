<p-toast></p-toast>
<div class="container-fluid container-fullw">
  <div class="p-fluid p-grid">
    <div class="p-col-12">
      <div>
        <p-fieldset [toggleable]="true">
          <ng-template pTemplate="header">
            <span *ngIf="question.id === null">{{ 'QUESTION.NEWQUESTION' | translate }}</span>
            <span *ngIf="question.id !== null">{{ 'QUESTION.EDITQUESTION' | translate }}</span>
          </ng-template>
          <form pFocusTrap name="questionForm" id="questionForm" novalidate (ngSubmit)="submitForm(true)" [formGroup]="questionForm" >
            <input type="hidden" name="id" id="questionId" formControlName="id" />
            <div class="p-fluid p-grid">
              <div class="p-col-6">

               
                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-marker-alt"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="questionNameFr" formControlName="nameFr" type="text" pInputText [required]="false"> 
                      <label for="questionNameFr" class="">{{ 'CONTENT.NAMEFR' | translate }}</label>
                    </span>
                  </div>
                </div>

                <div *ngIf="questionForm.controls['nameFr'].invalid && (questionForm.controls['nameFr'].dirty || questionForm.controls['nameFr'].touched)" class="alert alert-danger">
                  <ng-container *ngIf="questionForm.controls['nameFr'].errors !== null">
                  <div *ngIf="questionForm.controls['nameFr'].errors.required">
                   {{ 'VALIDATION.NAMEREQUIRED' | translate }}.
                  </div>
                  </ng-container>
                </div>

              </div><!--/p-col-6-->
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-marker-alt"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="questionNameAr" formControlName="nameAr" type="text" pInputText [required]="false"> 
                      <label for="questionNameAr" class="">{{ 'CONTENT.NAMEAR' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-marker-alt"></i>
                    </span>
                    <span class="p-float-label">
                      <input inputId="questionStartTime" formControlName="description" type="text" pInputText [required]="true" >
                      <label for="questionStartTime" class="req">{{ 'CONTENT.DESCRIPTION' | translate }}</label>
                    </span>
                  </div>
                </div>
                <div *ngIf="questionForm.controls['description'].invalid && (questionForm.controls['description'].dirty || questionForm.controls['description'].touched)" class="alert alert-danger">
                  <ng-container *ngIf="questionForm.controls['description'].errors !== null">
                  <div *ngIf="questionForm.controls['description'].errors.required">
                   {{ 'CONTENT.DESCRIPTION' | translate }}.
                  </div>
                  </ng-container>
                </div>

              </div><!--/p-col-6-->
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-marker-alt"></i>
                    </span>
                    <span class="p-float-label">
                      <input inputId="questionStartTime" formControlName="descriptionFr" type="text" pInputText [required]="true" >
                      <label for="questionStartTime" class="req">{{ 'CONTENT.DESCRIPTIONFR' | translate }}</label>
                    </span>
                  </div>
                </div>
                <div *ngIf="questionForm.controls['descriptionFr'].invalid && (questionForm.controls['descriptionFr'].dirty || questionForm.controls['descriptionFr'].touched)" class="alert alert-danger">
                  <ng-container *ngIf="questionForm.controls['descriptionFr'].errors !== null">
                  <div *ngIf="questionForm.controls['descriptionFr'].errors.required">
                   {{ 'CONTENT.DESCRIPTIONFR' | translate }}.
                  </div>
                  </ng-container>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
                <div class="p-col-6">

                  <div class="p-field p-col-12">
                    <div class="p-inputgroup">
                      <span class="p-inputgroup-addon">
                        <i class="icon-marker-alt"></i>
                      </span>
                      <span class="p-float-label">
                        <input inputId="questionStartTime" formControlName="descriptionAr" type="text" pInputText [required]="true" >
                        <label for="questionStartTime" class="req">{{ 'CONTENT.DESCRIPTIONAR' | translate }}</label>
                      </span>
                    </div>
                  </div>
                  <div *ngIf="questionForm.controls['descriptionAr'].invalid && (questionForm.controls['descriptionAr'].dirty || questionForm.controls['descriptionAr'].touched)" class="alert alert-danger">
                    <ng-container *ngIf="questionForm.controls['descriptionAr'].errors !== null">
                    <div *ngIf="questionForm.controls['descriptionAr'].errors.required">
                     {{ 'CONTENT.DESCRIPTIONAR' | translate }}.
                    </div>
                    </ng-container>
                  </div>
  
                </div><!--/p-col-6-->
                <div class="p-col-6">

                  <div class="p-field p-col-12">
                    <div class="p-inputgroup">
                      <span class="p-inputgroup-addon">
                        <i class="icon-link"></i>
                      </span>
                      <span class="p-float-label">
                        <input id="questionPushStreamUrl" formControlName="requiredPointsNumber" type="text" pInputText [required]="false"> 
                        <label for="questionPushStreamUrl" class="">{{ 'CONTENT.REQUIREDNUMBEROFPOINTS' | translate }}</label>
                      </span>
                    </div>
                  </div>
  
                </div><!--/p-col-6-->
             
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-image"></i>
                    </span>

                    <input type="file"  pButton pRipple icon="pi pi-folder" styleClass="p-button-info"  class="w-100" (change)="uploadPhoto($event)" accept="image/*" />
                  </div>
                </div>

              </div><!--/p-col-6-->

              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-marker-alt"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="question" formControlName="value" type="text" pInputText [required]="false"> 
                      <label for="questionPushStreamName" class="">{{ 'VALUE' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->
           

            <div class="p-fluid p-grid">
              <div class="p-col-12">
                <button type="button" (click)="submitForm(true)" class="btn btn-primary pull-right" [disabled]="disableSubmit">
                  <i class="fas fa-spinner fa-spin" *ngIf="disableSubmit && redirect"></i>
                  <i class="icon-save" *ngIf="!(disableSubmit && redirect)"></i>
                  <span class="p-ml-1">{{ 'ACTIONS.SAVE' | translate }}</span>
                </button>
              </div>
            </div>
          </form>
        </p-fieldset>
        
      </div>
    </div><!--/p-col-12-->
  </div><!--/p-fluid p-grid-->
  <p-scrollTop target="parent"></p-scrollTop>
</div><!--/container-fluid container-fullw-->
<!-- end: FORM -->

