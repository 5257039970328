<div>
    <form (ngSubmit)="onSubmit()">
        <label for="min">Min:</label>
        <input type="number" id="min" [(ngModel)]="min" name="min">

        <label for="max">Max:</label>
        <input type="number" id="max" [(ngModel)]="max" name="max">

        <button type="submit">Submit</button>
    </form>
</div>

<div *ngIf="statistics">
    <!-- <h3>Statistiques pour {{ selectedYear }}</h3>
    <select (change)="onYearChange($event)">
        <option *ngFor="let year of years" [value]="year">{{ year }}</option>
    </select> -->





    
    <h3>Statistiques pour {{ monthNames[selectedMonth] }}</h3>
    <select (change)="onMonthChange($event)">
        <option *ngFor="let month of monthss" [value]="month">{{
            monthNames[month] }}</option>
    </select>
    <div *ngFor="let month of months">
        <h4>{{ monthNames[month] }}</h4>
        <ul>
            <li *ngFor="let stat of statistics[selectedYear][month] | keyvalue">
                {{ stat.key }}: {{ stat.value }}
            </li>
        </ul>
    </div>
    <h3>Statistiques Mensuelles</h3>
    <table>
        <thead>
          <tr>
            <th rowspan="2">Année</th>
            <th rowspan="2">Mois</th>
            <th *ngFor="let label of pointLabels">{{ label }}</th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let yearData of tableDatatable">
            <tr *ngFor="let monthData of yearData.months; let isFirstMonth = first">
              <ng-container *ngIf="isFirstMonth">
                <td [attr.rowspan]="yearData.months.length">{{ yearData.year }}</td>
              </ng-container>
              <td>{{ monthData.month }}</td>
              <td *ngFor="let point of monthData.points">{{ point }}</td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    <canvas baseChart
        [datasets]="chartData"
        [labels]="chartLabels"
        [options]="chartOptions"
        [legend]="chartLegend"
        [chartType]="chartType">
    </canvas>
</div>
