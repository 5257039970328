import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DialogService } from 'primeng/dynamicdialog';
import { MessageService, ConfirmationService, PrimeNGConfig } from 'primeng/api';
import { Title } from '@angular/platform-browser';
import { NavigationService } from '../../../services/navigation/navigation.service';
import { ToolsService } from '../../../services/tools/tools.service';
import { Column } from '../../interfaces/column.interface';
import { ListDataService, ListColumnsService } from '../../services';
import { List, ListResult } from '../../interfaces';
import { FileUpload } from 'primeng/fileupload';
import { FormGroup, FormControl } from '@angular/forms';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
  providers: [MessageService, ConfirmationService, DialogService],
})
export class ListComponent implements OnInit, OnDestroy {
  public dateForm: FormGroup = new FormGroup({});

  public articlesToDelete: List = {};
  public list: List[] = [];
  public data: any = {};
  public selectedArticles: List[] = [];
  public submitted: boolean = false;
  public parameters: { [param: string]: string | string | string | string[] } = { month: '05', year: '2024' };
  public chunkSize: number = 200;
  public columns: Column[] = [];
  public isLoading: boolean = false;
  public isInitialized: boolean = false;
  public locale: string = (localStorage.language) ? localStorage.language : 'en';
  private _selectedColumns: Column[] = [];
  @ViewChild('importer', { static: false })
  public importer?: FileUpload;
  public importChooseLabel: string = 'Import';
  public importLabel: string = 'Import CSV';
  public month:any;
  public year:any;
  constructor(
    private translate: TranslateService,
    private navigation: NavigationService,
    private messageService: MessageService,
    private primengConfig: PrimeNGConfig,
    private titleService: Title,
    private listDataService: ListDataService,
    private listColumnsService: ListColumnsService,
  ) {
    this.importChooseLabel = this.translate.instant('TOOLS.IMPORT');
    this.importLabel = this.translate.instant('TOOLS.IMPORTCSV');
  }

  ngOnInit(): void {
    this.isInitialized = true;
    this.titleService.setTitle('List - Management');
    this.primengConfig.ripple = true;
    this.prepareFrom();

  }
  prepareFrom(): void {
    this.dateForm = new FormGroup({
      month: new FormControl(this.data.month, []),
    });
  }

 padTo2Digits(num:any) {
  return num.toString().padStart(2, '0');
}

  submitForm(redirect: boolean) {
    var date = new Date(this.dateForm.value.month);
    this.year = date.getFullYear();
    this.month = this.padTo2Digits(date.getMonth() + 1);
    this.getList();
    if (!this.dateForm.valid) {
      this.showErrors();
    }
  }

  showErrors() {
    this.showErrors();
    const invalidFields: string[] = [];
    Object.keys(this.dateForm.controls).forEach((field: any) => {
      const control = this.dateForm.get(field);
      if (control !== null) {
        control.markAsTouched({ onlySelf: true });
        if (control.invalid) {
          invalidFields.push(field);
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.isInitialized = false;
  }

  getList(): void {
    this.list = [];
    if (!this.isInitialized) {
      return;
    }
    this.parameters.month = this.month;
    this.parameters.year = this.year;
    if (parseInt(this.parameters.month + '', 10) === 0) {
      this.isLoading = true;
    }
    this.listDataService.getList(this.parameters).subscribe((response: ListResult | any) => {
      for (let i = 0; i < response.length; i++) {
      }
      this.isLoading = false;
      if (response !== null) {
        this.setColumns();
        if (parseInt(this.parameters.month + '', 10) === 0) {
          this.list = response.success.data;
        } else {
          this.list = this.list.concat(response.success.data);
        }
        // month=04&year=2023
        if (response.inlineCount > this.list.length) {
          this.parameters.month = (parseInt(this.parameters.month + '', this.month) + parseInt(this.parameters.year + '', this.year)) + '';
          this.getList();
        } else {
          this.parameters.month = '0';
        }
      }
    }, (error: any) => {
      ;
      this.isLoading = false;
      this.messageService.add({ severity: 'error', summary: this.translate.instant('UI.ERROR'), detail: this.translate.instant('UI.ARTICLESNOTLOADED') });
    });
  }

 

  setColumns() {
    if (this.columns.length > 0) {
      return;
    }
    this.columns = this.listColumnsService.getColumns();
    for (let i = 0; i < this.columns.length; i++) {
      if (this.columns[i].show) {
        this._selectedColumns.push(this.columns[i]);
      }
    }
  }

  @Input()
  get selectedColumns(): any[] {
    return this._selectedColumns;
  }

  set selectedColumns(val: any[]) {
    // restore original order
    this._selectedColumns = this.columns.filter((col: any) => val.includes(col));
    this.updateColumns();
  }

  updateColumns() {
    for (let i = 0; i < this.columns.length; i++) {
      const show = this._selectedColumns.includes(this.columns[i]);
      this.columns[i].show = show;
      this.navigation.setParamValue('posts.' + this.columns[i].field + '.showFiled', show);
    }
  }

  exportPdf() {
    ToolsService.exportPdf(this.list, this.columns, 'Liste Top 50');
  }

  exportExcel() {
    ToolsService.exportExcel(this.list, this.columns, 'Liste Top 50');
  }

  exportCsv() {
    ToolsService.exportCsv(this.list, this.columns, 'Liste Top 50');
  }

  refresh() {
    this.getList();
  }

}
