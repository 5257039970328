import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NavigationService } from '../../services/navigation/navigation.service';
import { Column } from '../interfaces/column.interface';

@Injectable({
  providedIn: 'root'
})
export class CategoriesColumnsService {

  constructor(
    private translate: TranslateService,
    private navigation: NavigationService,
  ) {
  }

  getColumns(): Column[] {
    return [
     // { field: 'id', sortField: 'id', title: this.translate.instant('ID'), show: (this.navigation.getParamValue('categories.id.showFiled', true) && true), displayInList: true, type: 'numericValue'},
      { field: 'name', sortField: 'name', title: this.translate.instant('CONTENT.NAME'), show: (this.navigation.getParamValue('categories.name.showFiled', true) && true), displayInList: true, type: 'textValue'},
      { field: 'nameAr', sortField: 'nameAr', title: this.translate.instant('CONTENT.NAMEAR'), show: (this.navigation.getParamValue('categories.nameAr.showFiled', false) && true), displayInList: true, type: 'textValue'},
      { field: 'nameFr', sortField: 'nameFr', title: this.translate.instant('CONTENT.NAMEFR'), show: (this.navigation.getParamValue('categories.nameFr.showFiled', false) && true), displayInList: true, type: 'textValue'},
      { field: 'description', sortField: 'description', title: this.translate.instant('CONTENT.DESCRIPTION'), show: (this.navigation.getParamValue('categories.description.showFiled', true) && true), displayInList: true, type: 'textValue'},
      { field: 'descriptionAr', sortField: 'descriptionAr', title: this.translate.instant('CONTENT.DESCRIPTIONAR'), show: (this.navigation.getParamValue('categories.descriptionAr.showFiled', false) && true), displayInList: true, type: 'textValue'},
      { field: 'descriptionFr', sortField: 'descriptionFr', title: this.translate.instant('CONTENT.DESCRIPTIONFR'), show: (this.navigation.getParamValue('categories.descriptionFr.showFiled', false) && true), displayInList: true, type: 'textValue'},
      //{ field: 'status', sortField: 'status', title: this.translate.instant('STATUS'), show: (this.navigation.getParamValue('categories.status.showFiled', false) &&true), type: 'textValue', displayInList: true},
      //{ field: 'active', sortField: 'active', title: this.translate.instant('ACTIVE'), show: (this.navigation.getParamValue('categories.active.showFiled', false) &&true), type: 'textValue', displayInList: true},
      { field: 'actions', title: this.translate.instant('CONTENT.ACTIONS'), type: 'static', show: true, displayInList: true, sortField: null},
    ];
  }

}
