import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { MessageService, ConfirmationService } from 'primeng/api';
import { Column } from '../../../interfaces/column.interface';
import { QuestionsColumnsService } from '../../../services/questions-columns.service';
import { Question } from '../../../interfaces/question.interface';

@Component({
  selector: 'app-question-session-details',
  templateUrl: './question-details.component.html',
  styleUrls: ['./question-details.component.scss'],
  providers: [MessageService, ConfirmationService, DynamicDialogRef, DynamicDialogConfig],
})
export class QuestionDetailsComponent implements OnInit, OnDestroy {

  public question: Question = {};
  public columns: Column[] = [];

  constructor(
    private router: Router,
    private dynamicDialogConfig: DynamicDialogConfig,
    private questionsColumnsService: QuestionsColumnsService,

  ) { }

  ngOnInit(): void {
    if (this.dynamicDialogConfig.data && typeof this.dynamicDialogConfig.data.question !== 'undefined') {
      this.question = this.dynamicDialogConfig.data.question;
    }
    this.columns = this.questionsColumnsService.getColumns();
    for (let i = 0; i <= this.columns.length; i++) {
      if (this.columns[i].field === "actions") {
        this.columns.splice(i, 1);
      }
    }
  }

  ngOnDestroy(): void {
  }

  list() {
    this.router.navigate(['/questions']);
  }

  add() {
    this.router.navigate(['/questions/add']);
  }

  edit() {
    this.router.navigate(['/questions/edit/' + this.question.id]);
  }

}
@Component({
  selector: 'app-question-session-details',
  templateUrl: './question-details.component.html',
  styleUrls: ['./question-details.component.scss'],
  providers: [MessageService, ConfirmationService],
})
export class DialogQuestionDetailsComponent extends QuestionDetailsComponent {
}

