import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { MessageService, ConfirmationService, PrimeNGConfig } from 'primeng/api';
import { Title } from '@angular/platform-browser';
import { NavigationService } from '../../../services/navigation/navigation.service';
import { ToolsService } from '../../../services/tools/tools.service';
import { Column } from '../../interfaces/column.interface';
import { DialogGiftFormComponent } from './gift-form/gift-form.component';
import { DialogGiftDetailsComponent } from './gift-details/gift-details.component';
import { GiftsDataService } from '../../services/gifts-data.service';
import { Gift, GiftsResult } from '../../interfaces/gift.interface';
import { GiftsColumnsService } from '../../services/gifts-columns.service';

@Component({
  selector: 'app-gift-sessions',
  templateUrl: './gifts.component.html',
  styleUrls: ['./gifts.component.scss'],
  providers: [MessageService, ConfirmationService, DialogService],
})
export class GiftsComponent implements OnInit, OnDestroy {

  private giftAddDialog: DynamicDialogRef = new DynamicDialogRef();
  private giftEditDialog: DynamicDialogRef = new DynamicDialogRef();
  private giftDetailsDialog: DynamicDialogRef = new DynamicDialogRef();
  public giftToDelete: Gift = {};
  public gifts: Gift[] = [];
  public columns: Column[] = [];
  public isLoading: boolean = false;
  public isInitialized: boolean = false;
  private _selectedColumns: Column[] = [];

  constructor(
    private translate: TranslateService,
    private navigation: NavigationService,
    private router: Router,
    private dialogService: DialogService,
    private messageService: MessageService,
    private primengConfig: PrimeNGConfig,
    private titleService: Title,
    private giftsDataService: GiftsDataService,
    private giftsColumnsService: GiftsColumnsService,
  ) {
  }

  ngOnInit(): void {
    this.isInitialized = true;
    this.titleService.setTitle('Gifts - Management');
    this.primengConfig.ripple = true;
    this.getGifts();
  }

  ngOnDestroy(): void {
    this.isInitialized = false;
  }
 
  getGifts(): void {
    this.isLoading = true;
    if (!this.isInitialized) {
      return;
    }
    this.giftsDataService.getGifts().subscribe((response: GiftsResult|any) => {
      this.isLoading = false;
      if (response !== null) {
        this.setColumns();
        this.gifts = response.success.data;
      }
    }, (error: any) => {;
      this.isLoading = false;
      this.messageService.add({severity: 'error', summary: this.translate.instant('UI.ERROR'), detail: this.translate.instant('UI.GIFTSNOTLOADED')});
    });
  }

  setColumns() {
    if (this.columns.length > 0) {
      return;
    }
    this.columns = this.giftsColumnsService.getColumns();
    for (let i = 0; i < this.columns.length; i++) {
      if (this.columns[i].show) {
        this._selectedColumns.push(this.columns[i]);
      }
    }
  }

  @Input()
  get selectedColumns(): any[] {
      return this._selectedColumns;
  }

  set selectedColumns(val: any[]) {
      // restore original order
      this._selectedColumns = this.columns.filter((col: any) => val.includes(col));
      this.updateColumns();
  }

  updateColumns() {
    for (let i = 0; i < this.columns.length; i++) {
      const show = this._selectedColumns.includes(this.columns[i]);
      this.columns[i].show = show;
      this.navigation.setParamValue('gift.' + this.columns[i].field + '.showFiled', show);
    }
  }

  exportPdf() {
    ToolsService.exportPdf(this.gifts, this.columns, 'Gifts');
  }

  exportExcel() {
    ToolsService.exportExcel(this.gifts, this.columns, 'Gifts');
  }

  exportCsv() {
    ToolsService.exportCsv(this.gifts, this.columns, 'Gifts');
  }

  refresh() {
    this.getGifts();
  }

  giftAdd(): void {
    if (!this.navigation.getParamValue('gift.addInDialog', true)) {
      this.router.navigate(['/gifts/add']);
      return;
    }
    this.giftAddDialog = this.dialogService.open(DialogGiftFormComponent, {
      header: '', // this.translate.instant('NEWgift'),
      width: '80%',
      contentStyle: { 'height': '800px', overflow: 'auto' },
      baseZIndex: 1000,
      data: {
        mode: 'add',
        gift: {id: null},
        giftClasses: this.giftsColumnsService.giftClasses,
        coaches: this.giftsColumnsService.coaches,
        users: this.giftsColumnsService.users,
      },
    });

    this.giftAddDialog.onClose.subscribe((response: any) => {
      if (response && response.refresh) {
        this.messageService.clear();
        this.messageService.add({severity: 'success', summary: 'Success', detail: 'Gift added'});
        this.getGifts();
      }
    });
  }

  giftEdit(gift: Gift): void {
    if (!this.navigation.getParamValue('gift.editInDialog', true)) {
      this.router.navigate(['/gifts/edit/' + gift.id]);
      return;
    }
    this.giftEditDialog = this.dialogService.open(DialogGiftFormComponent, {
      header: '', // this.translate.instant('EDITgift') + ' ' + gift.name,
      width: '70%',
      contentStyle: { 'height': '800px', overflow: 'auto' },
      baseZIndex: 1000,
      data: {
        mode: 'edit',
        gift: gift,
        giftClasses: this.giftsColumnsService.giftClasses,
        coaches: this.giftsColumnsService.coaches,
        users: this.giftsColumnsService.users,
      },
    });

    this.giftEditDialog.onClose.subscribe((response: any) => {
      if (response && response.refresh) {
        this.messageService.clear();
        this.messageService.add({severity: 'success', summary: 'Success', detail: 'Gifts updated'});
        this.getGifts();
      }
    });
  }

  giftDetails(gift: Gift): void {
    if (!this.navigation.getParamValue('gift.detailsInDialog', true)) {
      this.router.navigate(['/gifts/details/' + gift.id]);
      return;
    }
    this.giftDetailsDialog = this.dialogService.open(DialogGiftDetailsComponent, {
      width: '70%',
      contentStyle: { 'height': '800px', overflow: 'auto' },
      baseZIndex: 1000,
      data: {
        gift: gift,
      }
    });
  }

  giftDelete(gift: Gift): void {
    this.giftToDelete = gift;
    this.messageService.clear();
    this.messageService.add({
      key: 'giftDelete',
      sticky: true,
      severity: 'warn',
      summary: 'Are you sure you want delete ' + gift.nameFr + ' ?',
      detail: 'Confirm to proceed'
    });
  }

  onDeleteConfirm(): void {
    this.giftsDataService.deleteGift(this.giftToDelete).subscribe((res: any) => {
      this.messageService.clear();
      this.messageService.add({severity: 'success', summary: 'Success', detail: 'Gift deleted'});
      this.getGifts();
    }, (error: any) => {
      this.messageService.clear();
      this.messageService.add({severity: 'error', summary: 'Error', detail: 'Gift not deleted'});
    });
  }

  onDeleteReject(): void {
    this.giftToDelete = {};
    this.messageService.clear('giftDelete');
  }

}
