import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { MessageService, ConfirmationService } from 'primeng/api';
import { CategoriesColumnsService } from '../../../services';
import { Category } from '../../../interfaces';
import { Column } from '../../../interfaces/column.interface';

@Component({
  selector: 'app-category-details',
  templateUrl: './category-details.component.html',
  styleUrls: ['./category-details.component.scss'],
  providers: [MessageService, ConfirmationService, DynamicDialogRef, DynamicDialogConfig],
})
export class CategoryDetailsComponent implements OnInit {

  public category: Category = {};
  public columns: Column[] = [];

  constructor(
    private router: Router,
    private dynamicDialogConfig: DynamicDialogConfig,
    private categoriesColumnsService: CategoriesColumnsService,
  ) { }

  ngOnInit(): void {
    if (this.dynamicDialogConfig.data && typeof this.dynamicDialogConfig.data.category !== 'undefined') {
      this.category = this.dynamicDialogConfig.data.category;
    }
    this.columns = this.categoriesColumnsService.getColumns();
    for (let i = 0; i <= this.columns.length; i++) {
      if (this.columns[i].field === "actions") {
        this.columns.splice(i, 1);
      }
    }
  }

  list() {
    this.router.navigate(['/categories']);
  }

  add() {
    this.router.navigate(['/categories/add']);
  }

  edit() {
    this.router.navigate(['/categories/edit/' + this.category.id]);
  }

}
@Component({
  selector: 'app-category-details',
  templateUrl: './category-details.component.html',
  styleUrls: ['./category-details.component.scss'],
  providers: [MessageService, ConfirmationService],
})
export class DialogCategoryDetailsComponent extends CategoryDetailsComponent {
}

