<div class="container-fluid container-fullw">
  <div class="p-fluid p-grid">
    <div class="p-col-12">
      <div>
        <p-messages [(value)]="msgs"></p-messages>

        <p-fieldset [toggleable]="true">
          <ng-template pTemplate="header">
            <span *ngIf="competition.id === null">{{ 'COMPETITION.NEWCOMPETITION' | translate }}</span>
            <span *ngIf="competition.id !== null">{{ 'COMPETITION.EDITCOMPETITION' | translate }}</span>
          </ng-template>
          <form pFocusTrap name="competitionForm" id="competitionForm" novalidate (ngSubmit)="submitForm()"
            [formGroup]="competitionForm">
            <input type="hidden" name="id" id="competitionReservationId" formControlName="id" />
            <div class="p-fluid p-grid">
              <div class="p-col-6">
                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-tag"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="name" formControlName="name" type="text" pInputText [required]="true">
                      <label for="name" class="req">{{ 'CONTENT.NAME' | translate }}</label>
                    </span>
                  </div>
                </div>
                <div
                  *ngIf="competitionForm.controls['name'].invalid && (competitionForm.controls['name'].dirty || competitionForm.controls['name'].touched)"
                  class="alert alert-danger">
                  <ng-container *ngIf="competitionForm.controls['name'].errors !== null">
                    <div *ngIf="competitionForm.controls['name'].errors.required">
                      {{ 'VALIDATION.NAMEREQUIRED' | translate }}.
                    </div>
                  </ng-container>
                </div>
              </div>
              <div class="p-col-6">
                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-marker-alt"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="categoryNameAr" formControlName="nameAr" type="text" pInputText [required]="false">
                      <label for="categoryNameAr" class="">{{ 'CONTENT.NAMEAR' | translate }}</label>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="p-fluid p-grid">
              <div class="p-col-6">
                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-tag"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="nameFr" formControlName="nameFr" type="text" pInputText [required]="true">
                      <label for="nameFr" class="req">{{ 'CONTENT.NAMEFR' | translate }}</label>
                    </span>
                  </div>
                </div>
                <div
                  *ngIf="competitionForm.controls['nameFr'].invalid && (competitionForm.controls['nameFr'].dirty || competitionForm.controls['nameFr'].touched)"
                  class="alert alert-danger">
                  <ng-container *ngIf="competitionForm.controls['nameFr'].errors !== null">
                    <div *ngIf="competitionForm.controls['nameFr'].errors.required">
                      {{ 'VALIDATION.NAMEREQUIRED' | translate }}.
                    </div>
                  </ng-container>
                </div>
              </div>
              <div class="p-col-6">
                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-tag"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="objective" formControlName="objective" type="text" pInputText [required]="true">
                      <label for="objective" class="req">{{ 'CONTENT.OBJECTIVE' | translate }}</label>
                    </span>
                  </div>
                </div>
                <div
                  *ngIf="competitionForm.controls['objective'].invalid && (competitionForm.controls['objective'].dirty || competitionForm.controls['objective'].touched)"
                  class="alert alert-danger">
                  <ng-container *ngIf="competitionForm.controls['objective'].errors !== null">
                    <div *ngIf="competitionForm.controls['objective'].errors.required">
                      {{ 'VALIDATION.OBJECTIVEREQUIRED' | translate }}.
                    </div>
                  </ng-container>
                </div>
              </div>
              <div class="p-col-6">
                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-alarm-clock"></i>
                    </span>
                    <span class="p-float-label">
                      <p-calendar inputId="startDate" formControlName="startDate" [showTime]="true" hourFormat="24"
                        [showIcon]="true" [yearNavigator]="true" [monthNavigator]="true" [required]="false"
                        yearRange="1900:2030" [stepHour]="1" [stepMinute]="15" [stepSecond]="0"></p-calendar>
                      <label for="startDate" class="">{{ 'CONTENT.STARTDATE' | translate }} </label>
                    </span>
                  </div>
                </div>
              </div>
              <div class="p-col-6">
                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-alarm-clock"></i>
                    </span>
                    <span class="p-float-label">
                      <p-calendar inputId="endDate" formControlName="endDate" [showTime]="true" hourFormat="24"
                        [showIcon]="true" [yearNavigator]="true" [monthNavigator]="true" [required]="false"
                        yearRange="1900:2030" [stepHour]="1" [stepMinute]="15" [stepSecond]="0"></p-calendar>
                      <label for="endDate" class="">{{ 'CONTENT.ENDDATE' | translate }}</label>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="p-fluid p-grid">
              <div class="p-col-12">
                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-marker-alt"></i>
                    </span>
                    <span class="p-float-label">
                      <p-dropdown inputId="giftId" [autoDisplayFirst]="false" formControlName="gift" [options]="gifts"
                        (onChange)="onChange($event)" optionLabel="name" [required]="true" [filter]="true"></p-dropdown>
                      <label for="giftId" class="req">{{ 'GIFT.GIFT' | translate }}</label>
                    </span>
                  </div>
                </div>
                <div
                  *ngIf="competitionForm.controls['gift'].invalid && (competitionForm.controls['gift'].dirty || competitionForm.controls['gift'].touched)"
                  class="alert alert-danger">
                  <ng-container *ngIf="competitionForm.controls['gift'].errors !== null">
                    <div *ngIf="competitionForm.controls['gift'].errors.required">
                      {{ 'VALIDATION.GIFTREQUIRED' | translate }}.
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
            <div class="p-fluid p-grid">
              <div class="p-col-6">
                <div
                  *ngIf="competitionForm.controls['active'].invalid && (competitionForm.controls['active'].dirty || competitionForm.controls['active'].touched)"
                  class="alert alert-danger">
                  <ng-container *ngIf="competitionForm.controls['active'].errors !== null">
                    <div *ngIf="competitionForm.controls['active'].errors.required">
                      {{ 'VALIDATION.ENABLESTREAMINGREQUIRED' | translate }}.
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
            <div class="p-fluid p-grid">

            <!-- <div class="p-col-12">
              <div class="p-field p-col-12">
                <div class="p-inputgroup">
                  <span class="p-inputgroup-addon">
                    <i class="icon-marker-alt"></i>
                  </span>
                  <span class="p-float-label">
                    <input id="description" formControlName="description" type="text" pInputText [required]="true">
                    <label for="description" class="req">{{ 'CONTENT.DESCRIPTION' | translate }}</label>
                  </span>
                </div>
              </div>
              <div
                *ngIf="competitionForm.controls['description'].invalid && (competitionForm.controls['description'].dirty || competitionForm.controls['description'].touched)"
                class="alert alert-danger">
                <ng-container *ngIf="competitionForm.controls['description'].errors !== null">
                  <div *ngIf="competitionForm.controls['description'].errors.required">
                    {{ 'VALIDATION.DESCRIPTIONREQUIRED' | translate }}.
                  </div>
                </ng-container>
              </div>
            </div> -->
          </div>

            <div class="p-fluid p-grid">
              <div class="p-col-12">
                <button [disabled]="redirect" type="button" (click)="submitForm()" class="btn btn-primary pull-right">
                  <i class="fas fa-spinner fa-spin" *ngIf="redirect"></i>
                  <i class="icon-save" *ngIf="!(redirect)"></i>
                  <span class="p-ml-1">{{ 'ACTIONS.SAVE' | translate }}</span>
                </button>
              </div>
            </div>
          </form>
        </p-fieldset>
      </div>
    </div>
  </div>
  <p-scrollTop target="parent"></p-scrollTop>
</div>