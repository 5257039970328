import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NavigationService } from '../../services/navigation/navigation.service';
import { CacheService } from '../../services/cache/cache.service';
import { CommonService } from '../../services/common/common.service';
import { Column } from '../interfaces/column.interface';
import moment from 'moment';
import { GiftsDataService } from './gifts-data.service';
import { Gift, GiftsResult } from '../interfaces';
import { MembersDataService } from './members-data.service';
import { Member, MembersResult } from '../interfaces';
import { User, UsersResult } from '../interfaces';

@Injectable({
  providedIn: 'root'
})
export class CompetitionsColumnsService {

  public competitions: Gift[] = [];
  public members: Member[] = [];
  public users: User[] = [];
  public gifts: Gift[]=[];

  constructor(
    private translate: TranslateService,
    private navigation: NavigationService

  ) {
  }


  getColumns(): Column[] {
    return [
      //{ field: 'id', sortField: 'id', title: this.translate.instant('ID'), show: (this.navigation.getParamValue('competitions.id.showFiled', true) && true), displayInList: true, type: 'numericValue'},
      { field: 'name', sortField: 'name', title: this.translate.instant('CONTENT.NAME'), show: (this.navigation.getParamValue('competitions.name.showFiled', true) && true), displayInList: true, type: 'textValue'},
      { field: 'nameAr', sortField: 'nameAr', title: this.translate.instant('CONTENT.NAMEAR'), show: (this.navigation.getParamValue('competitions.nameAr.showFiled', true) && true), displayInList: true, type: 'textValue'},
      { field: 'nameFr', title: this.translate.instant('CONTENT.NAMEFR'), type: 'textValue',  show: (this.navigation.getParamValue('competitions.nameFr.showFiled', true) && true), displayInList: true, filterField: 'competition.id', displayField: 'label_', sortField: 'competition.label_', url: 'competition-sessions' },
      { field: 'startDate', title: this.translate.instant('CONTENT.STARTDATE'), type: 'dateValue', show: (this.navigation.getParamValue('competitions.startDate.showFiled', true) && true), displayInList: true,  sortField: 'startDate' ,valueFormatter: "dd-MM-yyyy h:mma"},
      { field: 'endDate', title: this.translate.instant('CONTENT.ENDDATE'), type: 'dateValue', show: (this.navigation.getParamValue('competitions.endDate.showFiled', true) && true), displayInList: true,  sortField: 'endDate' ,valueFormatter: "dd-MM-yyyy h:mma"},
      //{ field: 'createdAt', sortField: 'createdAt', title: this.translate.instant('CREATEDAT'), show: (this.navigation.getParamValue('competitions.createdAt.showFiled', true) && true), displayInList: true, type: 'dateValue', valueFormatter: this.translate.instant('DATETIMEFORMAT')},
      //{ field: 'modifiedAt', sortField: 'modifiedAt', title: this.translate.instant('MODIFIEDAT'), show: (this.navigation.getParamValue('competitions.modifiedAt.showFiled', false) && true), displayInList: true, type: 'dateValue', valueFormatter: this.translate.instant('DATETIMEFORMAT')},
      { field: 'objective', sortField: 'objective', title: this.translate.instant('CONTENT.OBJECTIVE'), show: (this.navigation.getParamValue('competitions.name.showFiled', true) && true), displayInList: true, type: 'textValue'},
      //{ field: 'description', sortField: 'description', title: this.translate.instant('CONTENT.DESCRIPTION'), show: (this.navigation.getParamValue('competitions.name.showFiled', true) && true), displayInList: true, type: 'textValue'},
      { field: 'giftName', title: this.translate.instant('CONTENT.GIFT'), type: 'textValue', filterData: this.competitions, show: (this.navigation.getParamValue('competitions.gift.showFiled', false) && true), displayInList: true, filterField: 'gifts.id', displayField: 'id', sortField: 'gifts.id', url: 'gifts' },
      { field: 'actions', title: this.translate.instant('CONTENT.ACTIONS'), type: 'static', show: true, displayInList: true, sortField: null},
    ];
  }

  format(fieldName: string, value: any) {
    if (fieldName.toLowerCase().indexOf('time') > -1) {
      return moment(value).format('YYYY-MM-DD HH:mm');
    } else if (fieldName.toLowerCase().indexOf('date') > -1) {
      return moment(value).format('YYYY-MM-DD');
    }
    return value;
  }

}
