import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../../services/auth/auth.service';
import { Message } from 'primeng/api';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit, OnDestroy {

  public isLoading = false;
  public msgs: Message[] = [];

  constructor(
    private authService: AuthService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.authService.logout();
    this.msgs.push({severity: 'success', summary: 'Logout success', detail: 'You will be redirected to login.'});
    setTimeout(() => {
      this.router.navigate(['/login']);
    }, 2000);
}

  ngOnDestroy(): void {
  }

}
