import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { MessageService, ConfirmationService, PrimeNGConfig } from 'primeng/api';
import { Title } from '@angular/platform-browser';
import { NavigationService } from '../../../services/navigation/navigation.service';
import { ToolsService } from '../../../services/tools/tools.service';
import { Column } from '../../interfaces/column.interface';
import { CategoriesDataService, CategoriesColumnsService } from '../../services';
import { DialogCategoryFormComponent } from './category-form/category-form.component';
import { DialogCategoryDetailsComponent } from './category-details/category-details.component';
import { Category, CategoriesResult } from '../../interfaces';

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss'],
  providers: [MessageService, ConfirmationService, DialogService],
})
export class CategoriesComponent implements OnInit, OnDestroy {

  private categoryAddDialog: DynamicDialogRef = new DynamicDialogRef();
  private categoryEditDialog: DynamicDialogRef = new DynamicDialogRef();
  private categoryDetailsDialog: DynamicDialogRef = new DynamicDialogRef();
  private categoryConverterDialog: DynamicDialogRef = new DynamicDialogRef();
  public categoryToDelete: Category = {};
  public categories: Category[] = [];
  public columns: Column[] = [];
  public isLoading: boolean = false;
  public isInitialized: boolean = false;
  private _selectedColumns: Column[] = [];

  constructor(
    private translate: TranslateService,
    private navigation: NavigationService,
    private router: Router,
    private dialogService: DialogService,
    private messageService: MessageService,
    private primengConfig: PrimeNGConfig,
    private titleService: Title,
    private categoriesDataService: CategoriesDataService,
    private categoriesColumnsService: CategoriesColumnsService,
  ) {
  }

  ngOnInit(): void {
    this.isInitialized = true;
    this.titleService.setTitle('Categories - Management');
    this.primengConfig.ripple = true;
   this.getCategories();
  }

  ngOnDestroy(): void {
   this.isInitialized = false;
  }

  getCategories(): void {
    this.isLoading = true;
    if (!this.isInitialized) {
      return;
    }

    this.categoriesDataService.getCategories().subscribe((response: CategoriesResult|any) => {
      this.isLoading = false;
      if (response !== null) {
        this.setColumns();
        this.categories = response.success.data;
      }
    }, (error: any) => {;
      this.isLoading = false;
      this.messageService.add({severity: 'error', summary: this.translate.instant('UI.ERROR'), detail: this.translate.instant('UI.CATEGORIESNOTLOADED')});
    });
  }
 
  setColumns() {
    if (this.columns.length > 0) {
      return;
    }
    this.columns = this.categoriesColumnsService.getColumns();
    for (let i = 0; i < this.columns.length; i++) {
      if (this.columns[i].show) {
        this._selectedColumns.push(this.columns[i]);
      }
    }
  }

  @Input()
  get selectedColumns(): any[] {
      return this._selectedColumns;
  }

  set selectedColumns(val: any[]) {
      // restore original order
      this._selectedColumns = this.columns.filter((col: any) => val.includes(col));
      this.updateColumns();
  }

  updateColumns() {
    for (let i = 0; i < this.columns.length; i++) {
      const show = this._selectedColumns.includes(this.columns[i]);
      this.columns[i].show = show;
      this.navigation.setParamValue('categories.' + this.columns[i].field + '.showFiled', show);
    }
  }

  exportPdf() {
    ToolsService.exportPdf(this.categories, this.columns, 'Catregories');
  }

  exportExcel() {
    ToolsService.exportExcel(this.categories, this.columns, 'Catregories');
  }

  exportCsv() {
    ToolsService.exportCsv(this.categories, this.columns, 'Catregories');
  }

  refresh() {
    this.getCategories();
  }

  categoryAdd(): void {
    if (!this.navigation.getParamValue('categories.addInDialog', true)) {
      this.router.navigate(['/categories/add']);
      return;
    }
    this.categoryAddDialog = this.dialogService.open(DialogCategoryFormComponent, {
      header: '', 
      width: '80%',
      contentStyle: { 'height': '800px', overflow: 'auto' },
      baseZIndex: 1000,
      data: {
        mode: 'add',
        category: {id: null},
      },
    });

    this.categoryAddDialog.onClose.subscribe((response: any) => {
      if (response && response.refresh) {
        this.messageService.clear();
        this.messageService.add({severity: 'success', summary: 'Success', detail: 'Catregories added'});
        this.getCategories();
      }
    });
  }

  categoryEdit(category: Category): void {
    if (!this.navigation.getParamValue('categories.editInDialog', true)) {
    
      this.router.navigate(['/categories/edit/' + category.id]);
      return;
    }
    this.categoryEditDialog = this.dialogService.open(DialogCategoryFormComponent, {
      header: '',
      width: '70%',
      contentStyle: { 'height': '800px', overflow: 'auto' },
      baseZIndex: 1000,
      data: {
        mode: 'edit',
        category: category,
      },
    });

    this.categoryEditDialog.onClose.subscribe((response: any) => {
      if (response && response.refresh) {
        this.messageService.clear();
        this.messageService.add({severity: 'success', summary: 'Success', detail: 'Catregory updated'});
        this.getCategories();
      }
    });
  }

  categoryDetails(category: Category): void {
    if (!this.navigation.getParamValue('categories.detailsInDialog', true)) {
      this.router.navigate(['/categories/details/' + category.id]);
      return;
    }
    this.categoryDetailsDialog = this.dialogService.open(DialogCategoryDetailsComponent, {
      width: '70%',
      contentStyle: { 'height': '800px', overflow: 'auto' },
      baseZIndex: 1000,
      data: {
        category: category,
      }
    });
  }

  categoryDelete(category: Category): void {
    this.categoryToDelete = category;
    this.messageService.clear();
    this.messageService.add({
      key: 'categoryDelete',
      sticky: true,
      severity: 'warn',
      summary: 'Are you sure you want delete ' + category.name + ' ?',
      detail: 'Confirm to proceed'
    });
  }

  onDeleteConfirm(): void {
    this.categoriesDataService.deleteCategory(this.categoryToDelete).subscribe((res: any) => {
      this.messageService.clear();
      this.messageService.add({severity: 'success', summary: 'Success', detail: 'Catregory deleted'});
      this.getCategories();
    }, (error: any) => {
      this.messageService.clear();
      this.messageService.add({severity: 'error', summary: 'Error', detail: 'Catregory not deleted'});
    });
  }

  onDeleteReject(): void {
    this.categoryToDelete = {};
    this.messageService.clear('categoryDelete');
  }


}
