<div class="app-body">
  <!-- Main content area -->
  <main class="main d-flex align-items-center">
    <!-- Container for content -->
    <div class="container">
      <!-- Row within the container -->
      <div class="row">
        <!-- Centered column within the row -->
        <div class="col-md-10 mx-auto">
          <!-- Messages component for displaying messages -->
          <p-messages [(value)]="msgs"></p-messages>

          <!-- Card group for layout -->
          <div class="card-group pb-2">
            <!-- Primary card on the left side -->
            <div class="card text-white bg-primary py-5 d-md-down-none" style="width:44%">
              <div class="card-body text-center">
                <div>
                  <!-- Content for the primary card -->
                </div>
              </div>
            </div>

            <!-- Card on the right side -->
            <div class="card p-4">
              <div class="card-body">
                <!-- Login form fieldset -->
                <fieldset>
                  <div class="login-brand mb-4"></div>

                  <!-- Login title -->
                  <h1 style=" color: #236790;" class="text-center font-weight-bold ">{{ 'UI.LOGIN' | translate }}</h1>

                  <!-- Form grid -->
                  <div class="p-fluid p-grid mt-3">
                    <!-- Username input field -->
                    <div class="p-field p-col-12 mt-3">
                      <div class="p-inputgroup">
                        <span class="p-inputgroup-addon">
                          <i class="pi pi-user"></i>
                        </span>
                        <span class="p-float-label">
                          <input type="text" id="phoneNumber" pInputText [(ngModel)]="credentials.phoneNumber"
                            [required]="true">
                          <label for="phoneNumber">{{ 'UI.USERNAME' | translate }}</label>
                        </span>
                      </div>
                    </div>

                    <!-- Password input field -->
                    <div class="p-field p-col-12">
                      <div class="p-inputgroup">
                        <span class="p-inputgroup-addon">
                          <i class="pi pi-lock"></i>
                        </span>
                        <span class="p-float-label">
                          <p-password id="password" [(ngModel)]="credentials.pinCode" [toggleMask]="true"
                            [required]="true">
                          </p-password>
                          <label for="password">{{ 'UI.PASSWORD' | translate }}</label>
                        </span>
                      </div>
                    </div>

                    <!-- Language selection dropdown -->
                    <div class="p-field p-col-12">
                      <label for="language">{{ 'UI.selectLanguage' | translate }}</label>
                      <div class="p-inputgroup">
                        <p-dropdown [options]="languageOptions" [(ngModel)]="selectedLanguage" (onChange)="onLanguageChange()" placeholder="{{ 'UI.selectLanguage' | translate }}"></p-dropdown>
                      </div>
                    </div>

                    <!-- Login button -->
                    <div class="p-field p-col-12 ">
                      <button type="button" class="btn btn-primary px-2 w-100" (click)="login()" [disabled]="isLoading">
                        {{ 'UI.LOGIN' | translate }}
                        <span *ngIf="isLoading" class="spinner-grow spinner-grow-sm float-right ml-2 text-light"
                          role="status"></span>
                      </button>
                    </div>

                  </div> <!-- End of form grid -->

                </fieldset> <!-- End of login fieldset -->
              </div>
            </div> <!-- End of card on the right side -->
          </div> <!-- End of card group -->

        </div> <!-- End of centered column within the row -->
      </div> <!-- End of row within the container -->
    </div> <!-- End of container for content -->
  </main> <!-- End of main content area -->
</div> <!-- End of app-body -->
