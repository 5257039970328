import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DialogService } from 'primeng/dynamicdialog';
import { MessageService, ConfirmationService, PrimeNGConfig } from 'primeng/api';
import { Title } from '@angular/platform-browser';
import { Column } from '../../interfaces/column.interface';
import { LocationDataService } from '../../services';
import { FormGroup, FormControl } from '@angular/forms';
import * as L from 'leaflet';
interface Location {
  longitude: number;
  latitude: number;
}

interface RawData {
  date: string;
  user_id: number;
  phone_number: string;
  longitude: number;
  latitude: number;
}
@Component({
  selector: 'app-location',
  templateUrl: './location.component.html',
  styleUrls: ['./location.component.scss'],
  providers: [MessageService, ConfirmationService, DialogService],
})
export class LocationComponent implements OnInit, OnDestroy {
  public dateForm: FormGroup = new FormGroup({});
  private map: L.Map | any;

  public location: [];
  public data: any = {};
  public parameters: { [param: string]: string | string | string | string[] } = {  day: '05', month: '05', year: '2023' };
  public columns: Column[] = [];
  public isLoading: boolean = false;
  public isInitialized: boolean = false;
  private _selectedColumns: Column[] = [];
  @ViewChild('importer', { static: false })
  public importChooseLabel: string = 'Import';
  public importLabel: string = 'Import CSV';
  public month:any;
  public day:any;
  public year:any;
  public phoneNumber:any;
  public dataLocation:any = []
  public rawData:any = []
  cleanedData: {longitude: number, latitude: number}[] = [];

  constructor(
    private translate: TranslateService,
    private messageService: MessageService,
    private primengConfig: PrimeNGConfig,
    private titleService: Title,
    private locationDataService: LocationDataService
  ) {
    this.location = [];

    this.importChooseLabel = this.translate.instant('TOOLS.IMPORT');
    this.importLabel = this.translate.instant('TOOLS.IMPORTCSV');
  }

  ngOnInit(): void {
    this.isInitialized = true;
    this.titleService.setTitle('Location - Management');
    this.primengConfig.ripple = true;
    this.prepareFrom();
   
     this.initMap();

  }
  prepareFrom(): void {
    this.dateForm = new FormGroup({
      month: new FormControl(this.data.month, []),
      phoneNumber: new FormControl('')
    });
  }

 padTo2Digits(num:any) {
  return num.toString().padStart(2, '0');
}

  submitForm(redirect: boolean) {
    var date = new Date(this.dateForm.value.month);
    this.phoneNumber = this.dateForm.value.phoneNumber
    this.day = ('0' + date.getDate()).slice(-2);
    this.year = date.getFullYear();
    this.month = this.padTo2Digits(date.getMonth() + 1);
    this.getLocation();
    if (!this.dateForm.valid) {
      this.showErrors();
    }
  }

  showErrors() {
    this.showErrors();
    const invalidFields: string[] = [];
    Object.keys(this.dateForm.controls).forEach((field: any) => {
      const control = this.dateForm.get(field);
      if (control !== null) {
        control.markAsTouched({ onlySelf: true });
        if (control.invalid) {
          invalidFields.push(field);
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.isInitialized = false;
  }

  getLocation(): void {
    this.isLoading = true;

    this.location = [];
    if (!this.isInitialized) {
      return;
    }
    this.parameters.day = this.day;
    this.parameters.month = this.month;
    this.parameters.year = this.year;
    this.parameters.phoneNumber = this.phoneNumber;

    if (parseInt(this.parameters.month + '', 10) === 0) {
      this.isLoading = true;
    }
    this.locationDataService.getLocation(this.parameters).subscribe((response:any) => {
      this.dataLocation = response.success;
      console.log('this.dataLocation', this.dataLocation)
      this.rawData = this.dataLocation
      this.cleanedData = this.dataLocation.map((data:any) => ({
        longitude: data.longitude,
        latitude: data.latitude
      }));
      console.log('this.cleanedData', this.cleanedData)
      this.addRoute();

      this.isLoading = false;



    }, (error: any) => {
      ;
      this.isLoading = false;
      this.messageService.add({ severity: 'error', summary: this.translate.instant('UI.ERROR'), detail: this.translate.instant('UI.ARTICLESNOTLOADED') });
    });
  }

 initMap(): void {
    this.map = L.map('map', {
      center: [36.7436872, 10.253828 ], // Position initiale de la carte
      zoom: 6
    });

    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
    }).addTo(this.map);
  }

  private addRoute(): void {
    const route = this.cleanedData.map((location: any) => {
      return { lat: location.latitude, lng: location.longitude };
  });

  if (route.length > 0) {
      console.log('route', route);
      // const polyline =  L.polyline(route, { color: 'red' }).addTo(this.map);
      this.addGroupedRoutes();

      // this.map.fitBounds(polyline.getBounds());

  } else {
      console.error('Route data is empty or invalid');
  }
  }


  cleanData(rawData: RawData[]): Location[] {
    return rawData.map(data => ({
      longitude: data.longitude,
      latitude: data.latitude
    }));
  }


  private groupCoordinatesByLatitudeRange(coordinates: any[], rangeSize: number): any {
    const groupedCoordinates: { [key: number]: any[] } = {};

    coordinates.forEach((coord:any) => {
      const range = Math.floor(coord.latitude / rangeSize);
      if (!groupedCoordinates[range]) {
        groupedCoordinates[range] = [];
      }
      groupedCoordinates[range].push(coord);
    });

    return groupedCoordinates;
  }

  private addGroupedRoutes(): void {
    const rangeSize = 1; // Define the latitude range size, e.g., 1 degree
    const groupedCoordinates = this.groupCoordinatesByLatitudeRange(this.cleanedData, rangeSize);

    for (const range in groupedCoordinates) {
      if (groupedCoordinates.hasOwnProperty(range)) {
        const route = groupedCoordinates[range].map((coord:any) => [coord.latitude, coord.longitude]);
        const polyline = L.polyline(route, { color: 'red' }).addTo(this.map);

        // Adjust the map view to fit the polyline bounds
        this.map.fitBounds(polyline.getBounds());
      }
    }
  }
}
