<ng-container [ngSwitch]="linkType">
  <a *ngSwitchCase="'disabled'"
     [ngClass]="item | appSidebarNavLink"
     [appHtmlAttr]="(item.attributes)?item.attributes:{}"
  >
    <app-sidebar-nav-link-content [item]="item"></app-sidebar-nav-link-content>
  </a>
  <a *ngSwitchCase="'external'"
     [ngClass]="item | appSidebarNavLink"
     [href]="href"
     [appHtmlAttr]="(item.attributes)?item.attributes:{}"
     (click)="linkClicked()"
  >
    <app-sidebar-nav-link-content [item]="item"></app-sidebar-nav-link-content>
  </a>
  <a *ngSwitchDefault
     [ngClass]="item | appSidebarNavLink"
     [appHtmlAttr]="(item.attributes)?item.attributes:{}"
     [target]="item.attributes?.target"
     [queryParams]="item.linkProps?.queryParams"
     [fragment]="item.linkProps?.fragment"
     [queryParamsHandling]="item.linkProps?.queryParamsHandling"
     [state]="item.linkProps?.state"
     [routerLink]="item.url"
     [routerLinkActive]="item.linkProps?.routerLinkActive || 'active'"
     [routerLinkActiveOptions]="(item.linkProps && item.linkProps.routerLinkActiveOptions && item.linkProps.routerLinkActiveOptions.exact) ? item.linkProps.routerLinkActiveOptions : {exact: false}"
     [preserveFragment]="(item.linkProps && item.linkProps.preserveFragment)?item.linkProps.preserveFragment:false"
     [skipLocationChange]="(item.linkProps && item.linkProps.skipLocationChange)?item.linkProps.skipLocationChange:false"
     [replaceUrl]="(item.linkProps && item.linkProps.replaceUrl)?item.linkProps.replaceUrl:false"
     [class.active]="linkActive && !item.linkProps?.routerLinkActiveOptions"
     (click)="linkClicked()"
  >
    <app-sidebar-nav-link-content [item]="item"></app-sidebar-nav-link-content>
  </a>
</ng-container>
