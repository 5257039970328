import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { MessageService, ConfirmationService, PrimeNGConfig } from 'primeng/api';
import { Title } from '@angular/platform-browser';
import { NavigationService } from '../../../services/navigation/navigation.service';
import { ToolsService } from '../../../services/tools/tools.service';
import { Column } from '../../interfaces/column.interface';
import { DialogQuestionFormComponent } from './question-form/question-form.component';
import { DialogQuestionDetailsComponent } from './question-details/question-details.component';
import { QuestionsDataService } from '../../services/questions-data.service';
import { Question, QuestionsResult } from '../../interfaces/question.interface';
import { QuestionsColumnsService } from '../../services/questions-columns.service';

@Component({
  selector: 'app-question-sessions',
  templateUrl: './questions.component.html',
  styleUrls: ['./questions.component.scss'],
  providers: [MessageService, ConfirmationService, DialogService],
})
export class QuestionsComponent implements OnInit, OnDestroy {

  private questionAddDialog: DynamicDialogRef = new DynamicDialogRef();
  private questionEditDialog: DynamicDialogRef = new DynamicDialogRef();
  private questionDetailsDialog: DynamicDialogRef = new DynamicDialogRef();
  public questionToDelete: Question = {};
  public questions: Question[] = [];
  public columns: Column[] = [];
  public isLoading: boolean = false;
  public isInitialized: boolean = false;
  private _selectedColumns: Column[] = [];

  constructor(
    private translate: TranslateService,
    private navigation: NavigationService,
    private router: Router,
    private dialogService: DialogService,
    private messageService: MessageService,
    private primengConfig: PrimeNGConfig,
    private titleService: Title,
    private questionsDataService: QuestionsDataService,
    private questionsColumnsService: QuestionsColumnsService,
  ) {
  }

  ngOnInit(): void {
    this.isInitialized = true;
    this.titleService.setTitle('Questions - Management');
    this.primengConfig.ripple = true;
    this.getQuestions();
  }

  ngOnDestroy(): void {
    this.isInitialized = false;
  }
 
  getQuestions(): void {
    this.isLoading = true;
    if (!this.isInitialized) {
      return;
    }
    this.questionsDataService.getQuestions().subscribe((response: QuestionsResult|any) => {
      this.isLoading = false;
      if (response !== null) {
        this.setColumns();
        this.questions = response.success.data;
      }
    }, (error: any) => {;
      this.isLoading = false;
      this.messageService.add({severity: 'error', summary: this.translate.instant('UI.ERROR'), detail: this.translate.instant('UI.questionSNOTLOADED')});
    });
  }

  setColumns() {
    if (this.columns.length > 0) {
      return;
    }
    this.columns = this.questionsColumnsService.getColumns();
    for (let i = 0; i < this.columns.length; i++) {
      if (this.columns[i].show) {
        this._selectedColumns.push(this.columns[i]);
      }
    }
  }

  @Input()
  get selectedColumns(): any[] {
      return this._selectedColumns;
  }

  set selectedColumns(val: any[]) {
      // restore original order
      this._selectedColumns = this.columns.filter((col: any) => val.includes(col));
      this.updateColumns();
  }

  updateColumns() {
    for (let i = 0; i < this.columns.length; i++) {
      const show = this._selectedColumns.includes(this.columns[i]);
      this.columns[i].show = show;
      this.navigation.setParamValue('question.' + this.columns[i].field + '.showFiled', show);
    }
  }

  exportPdf() {
    ToolsService.exportPdf(this.questions, this.columns, 'Questions');
  }

  exportExcel() {
    ToolsService.exportExcel(this.questions, this.columns, 'Questions');
  }

  exportCsv() {
    ToolsService.exportCsv(this.questions, this.columns, 'Questions');
  }

  refresh() {
    this.getQuestions();
  }

  questionAdd(): void {
    if (!this.navigation.getParamValue('question.addInDialog', true)) {
      this.router.navigate(['/questions/add']);
      return;
    }
    this.questionAddDialog = this.dialogService.open(DialogQuestionFormComponent, {
      header: '', // this.translate.instant('NEWquestion'),
      width: '80%',
      contentStyle: { 'height': '800px', overflow: 'auto' },
      baseZIndex: 1000,
      data: {
        mode: 'add',
        question: {id: null},
        users: this.questionsColumnsService.users,
      },
    });

    this.questionAddDialog.onClose.subscribe((response: any) => {
      if (response && response.refresh) {
        this.messageService.clear();
        this.messageService.add({severity: 'success', summary: 'Success', detail: 'Question added'});
        this.getQuestions();
      }
    });
  }

  questionEdit(question: Question): void {
    if (!this.navigation.getParamValue('question.editInDialog', true)) {
      this.router.navigate(['/questions/edit/' + question.id]);
      return;
    }
    this.questionEditDialog = this.dialogService.open(DialogQuestionFormComponent, {
      header: '', // this.translate.instant('EDITquestion') + ' ' + question.name,
      width: '70%',
      contentStyle: { 'height': '800px', overflow: 'auto' },
      baseZIndex: 1000,
      data: {
        mode: 'edit',
        question: question,
        users: this.questionsColumnsService.users,
      },
    });

    this.questionEditDialog.onClose.subscribe((response: any) => {
      if (response && response.refresh) {
        this.messageService.clear();
        this.messageService.add({severity: 'success', summary: 'Success', detail: 'questions updated'});
        this.getQuestions();
      }
    });
  }

  questionDetails(question: Question): void {
    if (!this.navigation.getParamValue('question.detailsInDialog', true)) {
      this.router.navigate(['/questions/details/' + question.id]);
      return;
    }
    this.questionDetailsDialog = this.dialogService.open(DialogQuestionDetailsComponent, {
      width: '70%',
      contentStyle: { 'height': '800px', overflow: 'auto' },
      baseZIndex: 1000,
      data: {
        question: question,
      }
    });
  }

  questionDelete(question: Question): void {
    this.questionToDelete = question;
    this.messageService.clear();
    this.messageService.add({
      key: 'questionDelete',
      sticky: true,
      severity: 'warn',
      summary: 'Are you sure you want delete ' + question.titleFr + ' ?',
      detail: 'Confirm to proceed'
    });
  }

  onDeleteConfirm(): void {
    this.questionsDataService.deleteQuestion(this.questionToDelete).subscribe((res: any) => {
      this.messageService.clear();
      this.messageService.add({severity: 'success', summary: 'Success', detail: 'Question deleted'});
      this.getQuestions();
    }, (error: any) => {
      this.messageService.clear();
      this.messageService.add({severity: 'error', summary: 'Error', detail: 'Question not deleted'});
    });
  }

  onDeleteReject(): void {
    this.questionToDelete = {};
    this.messageService.clear('questionDelete');
  }

}
