import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  public uri = `${environment.apiUrl}`;

  constructor(private http: HttpClient) {
  }

  countTotalData(): Observable<number | any> {
    return this.http.get(this.uri + `total/data`);
  }
}
